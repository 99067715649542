.custom-modal .modal-dialog {
    max-width: 500px; /* Default max-width for larger screens */
    margin: auto;
    width: 90%; /* Ensure the modal takes up 90% of the screen width */
  }
  
  @media (max-width: 768px) {
    .custom-modal .modal-dialog {
      max-width: 90%; /* For smaller screens, make the modal wider relative to screen size */
    }
  }
  
  .modal-body-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  