.HomePageAfterLogin .manage-your-health {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  margin-bottom: 20px;
}

.HomePageAfterLogin .homeoverlap-group {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
  /* height: 615px; */
}

.HomePageAfterLogin .start-for-free-wrapper {
  font-family: "Roboto", Helvetica;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
  opacity: 0.6;
}

.HomePageAfterLogin .exploreTheServices {
  font-family: "Roboto", Helvetica;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
}

.HomePageAfterLogin .exploreTheServicesbutton {
  border-radius: 8px;
  background-color: #5353e9;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 0px 20px 0px;
  width: 320px;
}

.HomePageAfterLogin .start-for-free {
  border-radius: 8px;
  background-color: #5353e9;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 0px 20px 0px;
  width: 320px;
}

.HomePageAfterLogin .span {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.HomePageAfterLogin .span-wrapper {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
  margin-bottom: 10px;
  text-align: center;
}

.HomePageAfterLogin .hometext-wrapper-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
}

.HomePageAfterLogin .learn-more {
  border-radius: 8px;
  color: #5353e9;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
}

.HomePageAfterLogin .hometext-wrapper-3 {
  cursor: pointer;
}

.HomePageAfterLogin .manage-all-your {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 10px;
  text-align: center;
}

.HomePageAfterLogin .hometext-wrapper-4 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
}

.HomePageAfterLogin .rectangle-wrapper {
  background-size: cover;
  border-radius: 10px;
}

.HomePageAfterLogin .img {
  height: auto;
  object-fit: cover;
  width: 80%;
  max-width: 80%;
  display: block;
}

.HomePageAfterLogin .overlap-2 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
}

.HomePageAfterLogin .understand-and-start {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  white-space: nowrap;
  margin-bottom: 20px;
  text-align: center;
}
