@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

.landing {
  background: #010000 0 0 / 100% 100%;
  height: 100vh;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.landing .overlap-22 {
  position: relative;
}

.landing .overlap-23 {
  background-color: #fdfdfe;
  border-radius: 25px;
  padding: 2rem;
  min-width: 800px;
  max-width: 800px;
  min-height: 600px;
  margin: 0 auto;
}

.landing .text-wrapper-52 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px !important;
}

.landing .text-wrapper-53 {
  color: #c60176;
  letter-spacing: 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 41px;
  margin-bottom: 20px !important;
  display: block;
  text-align: center;
}

.landing .text-wrapper-54 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
}

.landing .rectangle-4 {
  background-color: #00000038;
  border-radius: 10px;
  height: 346px;
  width: 350px;
}

video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.health-video {
  width: 100%;
  object-fit: cover;
}
.group-wrapper {
  height: 100%;
}

.health-brand-logo {
  padding: 1rem;
  background: #000;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
}
.health-brand-logo img {
  width: 250px;
}
.text-animation {
  display: flex;
  flex-wrap: nowrap;
}

.char {
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: rotateX("180deg");
  }
  100% {
    opacity: 1;
    transform: rotateX("180deg");
  }
}
.text-animation {
  font-family: Georgia;
  font-size: 36px;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.worldClass {
  color: #c60176;
}
.brand-story {
  color: #c60176;
  font-size: 30px;
  font-weight: 700;
}
.landing-content {
  font-family: "Poppins", sans-serif !important;
}
.main-heading {
  font-weight: 800;
  text-align: center;
}
