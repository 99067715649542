.trends-to-watch .div-test-values {
  border-radius: 12px;
  box-shadow: 0px -4px 12px #00000008;
  background-color: white;
  padding: 20px;
}

.trends-to-watch .test-value-heading {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
}

.trends-to-watch .div-table {
  align-items: center;
  background-color: #ffffff;
  border-radius: 0px 0px 9px 9px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 678px;
}

.trends-to-watch .v-body {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 680px;
}

.trends-to-watch .frame-26 {
  align-items: center;
  background-color: #ffffff;
  border-color: #e2e8f0;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 233px;
}

.trends-to-watch .th-text-start-2 {
  align-items: center;
  align-self: stretch;
  background-color: #e5e5eb9e;
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .span-6 {
  color: #757575;
  flex: 1;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .span-7 {
  color: #757575;
  font-family: "Material Icons", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.trends-to-watch .frame-27 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 157px;
}

.trends-to-watch .td-text-start-2 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-9 {
  color: #000000de;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 113px;
}

.trends-to-watch .td-text-start-3 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-10 {
  color: #000000de;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 113px;
}

.trends-to-watch .td-text-start-4 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-11 {
  color: #000000de;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 113px;
}

.trends-to-watch .td-text-start-5 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-12 {
  color: #000000de;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 113px;
}

.trends-to-watch .td-text-start-6 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-13 {
  color: #000000de;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 113px;
}

.trends-to-watch .frame-28 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 144px;
}

.trends-to-watch .td-text-start-7 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-14 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-8 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-15 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-9 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-16 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-10 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-17 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-11 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-18 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .frame-29 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 146px;
}

.trends-to-watch .td-text-start-12 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-19 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-13 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-20 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-14 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-21 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-15 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-22 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .td-text-start-16 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.trends-to-watch .text-wrapper-23 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.trends-to-watch .div-scroll {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 0px 0px 230px;
  position: relative;
  width: fit-content;
}

.trends-to-watch .scroll-vertical {
  background-color: #efeff3;
  box-shadow: inset 0px 1px 0px #e7e7e7;
  height: 15px;
  min-width: 448px;
  position: relative;
}

.trends-to-watch .scroll {
  background-color: #bdbdbd;
  border-radius: 4px;
  height: 88px;
  left: 44px;
  position: relative;
  top: -37px;
  transform: rotate(-90deg);
  width: 7px;
}

.trends-to-watch .div-trending-charts {
  border-radius: 12px;
  box-shadow: 0px -4px 12px #00000008;
  background-color: white;
  padding: 20px;
}

.trends-to-watch .heading-2 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
}

.trends-to-watch .description {
  color: #929292;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 10px;
}

.trends-to-watch .trending-charts {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.trends-to-watch .legendName {
  color: #11111166;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 50px;
}

.trends-to-watch .div-3 {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px -4px 12px #00000008;
  height: 100%;
}

.trends-to-watch .heading-wrapper {
  padding: 20px 0px 20px 20px;
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
}

.trends-to-watch .div-trends-parent {
  padding: 0px 20px 20px 20px;
}

.trends-to-watch .div-trend {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: #e2e8f0;
  border-radius: 18px;
  padding: 20px 0px 10px 10px;
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
}

.trends-to-watch .icon-emergency-wrapper {
  background-color: #e0236026;
  border-radius: 10.54px;
  padding: 8px;
  height: 41px;
}

.trends-to-watch .text-wrapper-44 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
}

.trends-to-watch .haemoglobin {
  color: #00000045;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.trends-to-watch .div-trend-inner-wrapper {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: #e2e8f0;
  border-radius: 18px;
  padding: 20px 0px 10px 10px;
  display: flex;
  gap: 15px;
}

.trends-to-watch .icon-emergency-instance-wrapper {
  background-color: #2372e026;
  border-radius: 10.54px;
  height: 41px;
  padding: 8px;
}

.trends-to-watch .text-wrapper-45 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
}

.trends-to-watch .text-wrapper-46 {
  color: #00000045;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.trends-to-watch .div-notice {
  padding: 20px;
}

.trends-to-watch .div-notice-banner {
  background-color: #efeff361;
  border-radius: 18px;
  padding: 16px 14px;
  display: flex;
  gap: 15px;
}

.trends-to-watch .icon-support-wrapper {
  background-color: #1111110f;
  border-radius: 10.54px;
  height: 41px;
  padding: 8px;
}

.trends-to-watch .text-wrapper-47 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
}

.trends-to-watch .text-wrapper-48 {
  color: #00000045;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}
/* ========================= */

.trending-charts-card-wrapper h3 {
  margin-bottom: 20px !important;
}

.chart-wrapper {
  width: 100%;
  height: 100%;
}
.chart-item {
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 15px 20px 10px;
  box-shadow: 0 7px 16px #5454e957;
}

.legend-data-wrapper {
  text-align: center;
}
.legend-data {
  vertical-align: middle;
  justify-content: space-between;
  align-items: center;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
}
.legend-data p {
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins";
}

.element-overview .div-my-health-wrapper {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 20px 24px;
  box-shadow: 0 7px 16px #5454e957;
}
.element-overview .heading-5 {
  color: #000;
  letter-spacing: 0;
  margin-bottom: 10px;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
}
/* .element-overview .overlap-group-4 {
  background-color: #f4f4f4;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 19px !important;
} */
.note-content {
  font-size: 12px;
  font-weight: 600;
}
.health-trend-content {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  list-style: inside;
  line-height: 18px;
  margin-bottom: 10px !important;
}
