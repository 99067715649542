.login-email {
  height: 100%;
  width: 100%;
}

.login-email .overlap-8 {
  background-image: url(https://jdm-planet.com/wp-content/uploads/2015/01/Login-background.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.login-email .overlap {
  background-image: url("./images/lab.avif");
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.login-email .overlapdoc {
  background-image: url("./images/doc.jpeg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.login-email .overlapdietician {
  background-image: url("./images/die.avif");
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.login-email .overlapresearch {
  background-image: url("./images/research.avif");
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.login-email .overlapGym {
    background-image: url("./images/gymbackground.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.login-email .overlap-9 {
  background-color: #fdfdfe;
  border-radius: 25px;
  padding: 40px;
  width: 580px;
}

.login-email .group-13 {
  display: flex;
  margin-bottom: 10px;
}

.login-email .overlap-group-6 {
  background-color: #f3f3f3;
  border-radius: 30px;
  padding: 5px;
  display: flex;
}

.login-email .overlap-10 {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 15px 50px 15px 50px;
}

.login-email .text-wrapper-14 {
  color: #5353e9;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.login-email .text-wrapper-15 {
  color: #858585;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  padding: 15px 50px 15px 50px;
}

.login-email .text-wrapper-16 {
  color: #5353e9;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  text-decoration: underline;
  white-space: nowrap;
  margin-bottom: 20px;
}

.login-email .group-14 {
  margin-bottom: 10px;
}

.login-email .text-wrapper-17 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}

.login-email .overlap-group-7 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d6d7d9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.login-email .emailinput {
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  border: none;
  outline: none;
  width: 100%;
}

.login-email .emailinput::placeholder {
  color: #c6c6c6;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
}


.login-email .loginHeading {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}



.login-email .by-clicking-here-i {
  color: transparent;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 20px;
}

.login-email .byclick {
  color: #000000;
}

.login-email .termsandcondition {
  color: #5353e9;
  text-decoration: underline;
  cursor: pointer;
}

.login-email .group-16 {
  margin-bottom: 20px;
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  border: none;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.login-email .group-16disabled {
  margin-bottom: 20px;
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  border: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.login-email .group-17 {
  margin-bottom: 10px;
}

.login-email .text-wrapper-23 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}

.login-email .don-t-have-an {
  color: transparent;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 20px;
  text-align: center;
}

.login-email .text-wrapper-24 {
  color: #000000;
  font-weight: 300;
}

.login-email .signup {
  color: #5353e9;
  font-weight: 500;
}

.login-email .group-18 {
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.login-email .health-buddy {
  height: 43px;
  width: 250px;
}

.login-email .shape {
  height: 53px;
  left: 73px;
  position: absolute;
  top: 0;
  width: 53px;
}

.login-email .group-19 {
  height: 21px;
  left: 14px;
  position: absolute;
  top: 9px;
  width: 362px;
}

.login-email .battery-3 {
  height: 11px;
  left: 338px;
  position: absolute;
  top: 4px;
  width: 24px;
}

.login-email .overlap-group-8 {
  border: 1px solid;
  border-color: #ffffff59;
  border-radius: 2.67px;
  height: 11px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.login-email .capacity-3 {
  background-color: #ffffff;
  border-radius: 1.33px;
  height: 7px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 18px;
}

.login-email .cap-3 {
  height: 4px;
  left: 23px;
  position: absolute;
  top: 4px;
  width: 1px;
}

.login-email .wifi-3 {
  height: 11px;
  left: 318px;
  position: absolute;
  top: 4px;
  width: 15px;
}

.login-email .cellular-connection-3 {
  height: 11px;
  left: 296px;
  position: absolute;
  top: 5px;
  width: 17px;
}

.login-email .time-style-3 {
  height: 21px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 54px !important;
}

.login-email .time-style-4 {
  color: #ffffff !important;
  font-family: "Roboto", Helvetica !important;
  font-weight: 400 !important;
  top: 3px !important;
}

.login-email .time-style-5 {
  font-family: unset !important;
  font-style: unset !important;
}
.background-container {
  background-image: url("./images/role.jpeg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content-container {
  background-color: white;
  border: 1px solid black;
  width: 40%;
  height: 60%;
  border-radius: 20px;
  margin-top: -8%; 
}
.logo-container {
    position: absolute;
    top: 10px;
    left: 10px;
}

@media (max-width: 768px) {
  .login-email .overlap-9 {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px;
  }

  .login-email .loginHeading {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .login-email .group-16, .login-email .group-16disabled {
    font-size: 14px;
    padding: 15px;
  }

  .login-email .text-wrapper-14, .login-email .text-wrapper-15 {
    padding: 10px 20px;
    font-size: 14px;
  }

  .login-email .overlap-group-7 {
    padding: 5px;
  }

  .login-email .emailinput {
    font-size: 14px;
  }

  .login-email .by-clicking-here-i {
    font-size: 11px;
    padding: 10px;
  }

  .login-email .termsandcondition {
    font-size: 11px;
  }

  .login-email .group-18 {
    padding: 10px;
  }

  .login-email .health-buddy {
    width: 200px;
    height: auto;
  }

  .login-email .overlap-group-6 {
    align-items: center;
  }
  .login-email .overlap-10 {
    padding: 10px 20px;
  }
}