.navs-overview {

}

.navs-overview .menu-item {

}

.navs-overview .frame {
  border-radius: 6px;

}

.navs-overview .div {
  align-items: center;
  display: flex;
  gap: 19px;
  width: fit-content;
  padding: 15px 10px 0px 10px;
}

.navs-overview .overview {
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: fit-content;
}

.navs-overview.default {
  top: 20px;
}

.navs-overview.variant-2 {
  top: 94px;
}

.navs-overview.default .frame {
  background-color: #ffffff1a;
}

.navs-overview.default .overview {
  color: #ffffff;
}

.navs-overview.variant-2 .overview {
  color: #888888;
}
