.ChangePassword {
    height: 100%;
    width: 100%;
}

.ChangePassword .overlap-8 {
    background-image: url(https://jdm-planet.com/wp-content/uploads/2015/01/Login-background.jpg);
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.ChangePassword .rectangle-6 {
    background-color: #fdfdfe;
    border-radius: 25px;
    width: 580px;
    padding: 40px;
}

.ChangePassword .group-18 {
    padding: 20px;
}

.ChangePassword .health-buddy {
    width: 250px;
}

.ChangePassword .span-wrapper-3 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  .ChangePassword .lock {
    width: 100px;
    height: 100px;
}

.ChangePassword input[type="password"]::-ms-reveal,
.ChangePassword input[type="password"]::-ms-clear {
  display: none;
}

/* For other browsers */
.ChangePassword input[type="password"]::-webkit-eye,
.ChangePassword input[type="password"]::-webkit-search-clear-button,
.ChangePassword input[type="password"]::-webkit-search-decoration,
.ChangePassword input[type="password"]::-webkit-search-results-button,
.ChangePassword input[type="password"]::-webkit-search-results-decoration {
  display: none;
}