.popup-header-icon {
  text-align: center;
}
.popup-heading {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.popup-description {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}
.popup-description p {
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 20px;
}
.footer-action {
  justify-content: center !important;
  border-top: 1px solid #e2e2e2;
}
.footer-action button {
  font-size: 20px;
  text-transform: capitalize;
}
.uhid-number {
  color: #5353e9;
  font-weight: 600;
}
.popup-description img {
  width: 100%;
  height: 100%;
}
.popup-description h3 {
  margin: 20px 0 10px !important;
  color: #000;
  font-size: 24px;
}
