/* .element-chronology-of-lab-history {
  background-color: #f9fbfc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

/* .element-chronology-of-lab-history .div-2 {
  background-color: #f9fbfc;
  border: 1px none;
  height: 1080px;
  position: relative;
  width: 100%;
} */

.timeline-calender-container {
  background: #fff;
  border-radius: 14px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 14px 1px #d4d3d3;
}
.calendar-table-row {
  background: #e5e5eb;
  border-radius: 14px 14px 0 0;
  padding: 9px 15px 9px 15px;
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.day {
  margin: 35px;
  padding: 13px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #757575;
  font-family: Roboto;
  font-size: 19px;
  font-weight: 700;
  line-height: 20px;
}


.prev-next-button {
  display: inline-block;
  cursor: pointer;
  color: #8d8b8b;
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  padding: 4px 5px 0;
}

/* .prev-next-button {
  cursor: pointer;
  color: #8d8b8b;
  border: 1px solid #b1b1b1;
  border-radius: 8px;
  padding: 3px 3px 0;
} */

.test-done {
  color: #1bb1b1;
  background: #4dbeef33;
  border-radius: 7px;
  padding: 4px 10px;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px !important;
  font-size: 12px;
}
.test-done.not-available {
  background: #939393;
  color: #fff;
}
.text-content {
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 12px;
  border: 1px solid #c7c6c6;
}

.upload-button.col {
  text-align: left;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 769px) {
  .upload-button.col-md-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 50% !important;
  }
}

.css-quj9j6-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 0px !important;
}
.calendar-table-cell {
  min-width: 150px;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.calendar-table-cell.css-1ex1afd-MuiTableCell-root {
  border-left: dashed 3px #ccc;
}
.main-table-container {
  min-height: 130px;
}
.table-header-tablecell {
  background: #e5e5eb !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #6e6d6d !important;
}

.no-data-found {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #7b7979;
}
.three-dot {
  font-size: 30px;
  color: darkgrey;
}

/* ====================================================================================================================== */
.element-chronology-of-lab-history .overlap {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 269px;
}

.element-chronology-of-lab-history .text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 114px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 89px;
  white-space: nowrap;
}

.element-chronology-of-lab-history .top-nav {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  height: 74px;
  left: 269px;
  position: absolute;
  top: 80px;
  width: 1171px;
}

.element-chronology-of-lab-history .overlap-group {
  box-shadow: 0px 1px 0px #e2e8f0;
  height: 80px;
  width: 1185px;
}

.element-chronology-of-lab-history .frame-18 {
  align-items: flex-start;
  display: flex;
  left: 11px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .topnav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .menu-item-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-19 {
  height: 54px;
  min-width: 140px;
  position: relative;
}

.element-chronology-of-lab-history .frame-20 {
  align-items: center;
  display: flex;
  gap: 19px;
  left: 25px;
  position: relative;
  top: 15px;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-2 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .menu-item-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .menu-item-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-22 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-3 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .topnav-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .menu-item-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-24 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-4 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .topnav-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .menu-item-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-26 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-5 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-dashborad {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

/* .element-chronology-of-lab-history .tests-history {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 304px;
  position: absolute;
  top: 200px;
  width: fit-content;
} */

.element-chronology-of-lab-history .text-wrapper-6 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 39px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .timeline {
  border-radius: 12px;
  box-shadow: 0px -4px 12px #00000008;
  height: 178px;
  min-width: 1101px;
  overflow: hidden;
  position: relative;
}

.element-chronology-of-lab-history .overlap-group-2 {
  height: 179px;
  position: relative;
  width: 1101px;
}

.element-chronology-of-lab-history .rectangle {
  background-color: #ffffff;
  border-radius: 14px;
  height: 177px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 1101px;
}

.element-chronology-of-lab-history .vector-2 {
  height: 124px;
  left: 463px;
  position: absolute;
  top: 54px;
  width: 2px;
}

.element-chronology-of-lab-history .group-3 {
  background-color: #efeff3;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e0e0e0;
  height: 54px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1101px;
}

.element-chronology-of-lab-history .rectangle-2 {
  background-color: #5353e9;
  border-radius: 6px;
  box-shadow: 0px 5px 8px -2px #5353e952;
  height: 25px;
  left: 582px;
  position: absolute;
  top: 14px;
  width: 102px;
}

.element-chronology-of-lab-history .group-4 {
  height: 138px;
  left: 617px;
  position: absolute;
  top: 40px;
  width: 35px;
}

.element-chronology-of-lab-history .frame-28 {
  align-items: flex-start;
  display: flex;
  gap: 83px;
  left: 83px;
  position: absolute;
  top: 14px;
  width: fit-content;
}

.element-chronology-of-lab-history .group-5 {
  height: 164.26px;
  min-width: 90px;
  position: relative;
}

.element-chronology-of-lab-history .vector-3 {
  height: 123px;
  left: 44px;
  position: absolute;
  top: 40px;
  width: 2px;
}

.element-chronology-of-lab-history .may {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-7 {
  color: #a0a0a0;
}

.element-chronology-of-lab-history .text-wrapper-8 {
  color: #111111;
}

.element-chronology-of-lab-history .group-6 {
  height: 164.26px;
  min-width: 86px;
  position: relative;
}

.element-chronology-of-lab-history .vector-4 {
  height: 123px;
  left: 42px;
  position: absolute;
  top: 40px;
  width: 2px;
}

.element-chronology-of-lab-history .apr {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-9 {
  color: #a0a0a0;
}

.element-chronology-of-lab-history .text-wrapper-10 {
  color: #111111;
}

.element-chronology-of-lab-history .group-wrapper {
  height: 25px;
  min-width: 86px;
  position: relative;
}

.element-chronology-of-lab-history .mar-wrapper {
  height: 25px;
  position: relative;
  width: 88px;
}

.element-chronology-of-lab-history .mar {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-11 {
  color: #11111180;
}

.element-chronology-of-lab-history .text-wrapper-12 {
  color: #111111;
}

.element-chronology-of-lab-history .group-7 {
  height: 164.26px;
  min-width: 87px;
  position: relative;
}

.element-chronology-of-lab-history .vector-5 {
  height: 123px;
  left: 42px;
  position: absolute;
  top: 40px;
  width: 2px;
}

.element-chronology-of-lab-history .feb {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-13 {
  color: #ffffff80;
}

.element-chronology-of-lab-history .text-wrapper-14 {
  color: #ffffff;
}

.element-chronology-of-lab-history .group-8 {
  height: 164.26px;
  min-width: 87px;
  position: relative;
}

.element-chronology-of-lab-history .vector-6 {
  height: 123px;
  left: 42px;
  position: absolute;
  top: 40px;
  width: 2px;
}

.element-chronology-of-lab-history .jan {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-15 {
  color: #a0a0a0;
}

.element-chronology-of-lab-history .text-wrapper-16 {
  color: #111111;
}

.element-chronology-of-lab-history .group-9 {
  height: 164.26px;
  margin-right: -2px;
  min-width: 89px;
  position: relative;
}

.element-chronology-of-lab-history .vector-7 {
  height: 123px;
  left: 43px;
  position: absolute;
  top: 40px;
  width: 2px;
}

.element-chronology-of-lab-history .dec {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-17 {
  color: #a0a0a0;
}

.element-chronology-of-lab-history .text-wrapper-18 {
  color: #111111;
}

.element-chronology-of-lab-history .frame-29 {
  align-items: flex-start;
  background-color: #fcdbdb;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  left: 160px;
  padding: 2px 7px;
  position: absolute;
  top: 84px;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-19 {
  color: #ef4d4d;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-30 {
  align-items: flex-start;
  background-color: #e1f3dd;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  left: 160px;
  padding: 2px 7px;
  position: absolute;
  top: 118px;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-20 {
  color: #39b11b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-31 {
  align-items: flex-start;
  background-color: #f3eadd;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  left: 577px;
  padding: 2px 7px;
  position: absolute;
  top: 118px;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-21 {
  color: #b1751b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-32 {
  align-items: flex-start;
  background-color: #dddff3;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  left: 810px;
  padding: 2px 7px;
  position: absolute;
  top: 112px;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-22 {
  color: #5353e9;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .table {
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1101px;
}

.element-chronology-of-lab-history .v-body {
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 4px;
  height: 335px;
  min-width: 1101px;
  overflow: hidden;
  position: relative;
}

.element-chronology-of-lab-history .frame-33 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 204px;
}

.element-chronology-of-lab-history .td-text-start-2 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-23 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-3 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-24 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-4 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-25 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-5 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-26 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-6 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-27 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .frame-34 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 204px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 190px;
}

.element-chronology-of-lab-history .th-text-start-2 {
  align-items: center;
  background-color: #e5e5eb9e;
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 10px 16px;
  position: relative;
  width: 190px;
}

.element-chronology-of-lab-history .span-6 {
  color: #757575;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 130px;
}

.element-chronology-of-lab-history .span-7 {
  color: #757575;
  font-family: "Material Icons", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .td-text-start-7 {
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  margin-right: -58px;
  padding: 10px 16px;
  position: relative;
  width: 248px;
}

.element-chronology-of-lab-history .text-wrapper-28 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: 109px;
}

.element-chronology-of-lab-history .td-text-start-8 {
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  margin-right: -64px;
  padding: 10px 16px;
  position: relative;
  width: 254px;
}

.element-chronology-of-lab-history .text-wrapper-29 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: 109px;
}

.element-chronology-of-lab-history .td-text-start-9 {
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  margin-right: -64px;
  padding: 10px 16px;
  position: relative;
  width: 254px;
}

.element-chronology-of-lab-history .text-wrapper-30 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: 109px;
}

.element-chronology-of-lab-history .td-text-start-10 {
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  margin-right: -64px;
  padding: 10px 16px;
  position: relative;
  width: 254px;
}

.element-chronology-of-lab-history .text-wrapper-31 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: 109px;
}

.element-chronology-of-lab-history .td-text-start-11 {
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  margin-right: -64px;
  padding: 10px 16px;
  position: relative;
  width: 254px;
}

.element-chronology-of-lab-history .text-wrapper-32 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: 109px;
}

.element-chronology-of-lab-history .frame-35 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 394px;
  position: absolute;
  top: 0;
  width: 224px;
}

.element-chronology-of-lab-history .td-text-start-12 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .frame-36 {
  align-items: flex-start;
  background-color: #fcdbdb;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-33 {
  color: #ef4d4d;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-37 {
  align-items: flex-start;
  background-color: #e1f3dd;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-34 {
  color: #39b11b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-35 {
  color: #0000004c;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-13 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .frame-38 {
  align-items: flex-start;
  background-color: #f3eadd;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-36 {
  color: #b1751b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .td-text-start-14 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .frame-39 {
  align-items: flex-start;
  background-color: #1bb1b126;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-37 {
  color: #1bb1b1;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .td-text-start-15 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .frame-40 {
  align-items: flex-start;
  background-color: #1b2ab126;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-38 {
  color: #5353e9;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .td-text-start-16 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .frame-41 {
  align-items: flex-start;
  background-color: #39b11b26;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .text-wrapper-39 {
  color: #39b11b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-chronology-of-lab-history .frame-42 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 618px;
  position: absolute;
  top: 0;
  width: 231px;
}

.element-chronology-of-lab-history .td-text-start-17 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .ellipse {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-40 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-18 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .ellipse-2 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-41 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-19 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .ellipse-3 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-42 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-20 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .ellipse-4 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-43 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .td-text-start-21 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-chronology-of-lab-history .ellipse-5 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-44 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-chronology-of-lab-history .overlap-group-3 {
  height: 335px;
  left: 849px;
  position: absolute;
  top: 0;
  width: 252px;
}

.element-chronology-of-lab-history .frame-43 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 237px;
}

.element-chronology-of-lab-history .scroll-vertical {
  background-color: #efeff3;
  height: 15px;
  left: 77px;
  position: absolute;
  top: 160px;
  transform: rotate(-90deg);
  width: 335px;
}

.element-chronology-of-lab-history .scroll {
  background-color: #bdbdbd;
  border-radius: 34px;
  height: 88px;
  left: 222px;
  position: relative;
  top: -37px;
  transform: rotate(-90deg);
  width: 7px;
}

.element-chronology-of-lab-history .div-topbar {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 1px 0px #e2e8f0;
  display: flex;
  left: 269px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-chronology-of-lab-history .div-column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 25px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .div-search {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .div-search-inner {
  align-items: center;
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .icon {
  height: 20px;
  min-width: 20px;
  position: relative;
}

.element-chronology-of-lab-history .text-wrapper-45 {
  color: #636a81;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 639px;
}

.element-chronology-of-lab-history .div-tools-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 17px 36px 17px 0px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .div-tools {
  align-items: center;
  display: flex;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.element-chronology-of-lab-history .div-actions {
  height: 34px;
  min-width: 83px;
  position: relative;
}

.element-chronology-of-lab-history .overlap-group-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 34px;
}

.element-chronology-of-lab-history .overlap-group-4 {
  height: 27px;
  left: 8px;
  position: relative;
  width: 25px;
}

.element-chronology-of-lab-history .chat-alt {
  height: 18px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 18px;
}

.element-chronology-of-lab-history .ellipse-6 {
  background-color: #dc3545;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 10px;
}

.element-chronology-of-lab-history .alarm-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 34px;
}

.element-chronology-of-lab-history .alarm {
  height: 18px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 18px;
}

.element-chronology-of-lab-history .div-profile {
  height: 46px;
  min-width: 183px;
  position: relative;
}

.element-chronology-of-lab-history .profile {
  height: 46px;
  left: 107px;
  position: absolute;
  top: 0;
  width: 76px;
}

.element-chronology-of-lab-history .group-10 {
  background-image: url(../../../static/img/ellipse-2824.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.element-chronology-of-lab-history .chevron-down {
  height: 20px;
  left: 56px;
  position: absolute;
  top: 13px;
  width: 20px;
}

.element-chronology-of-lab-history .name {
  height: 35px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 92px;
}

.element-chronology-of-lab-history .text-wrapper-46 {
  color: #212b36;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: -1px;
  white-space: nowrap;
}

.element-chronology-of-lab-history .text-wrapper-47 {
  color: #637381;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 26px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 20px;
  white-space: nowrap;
}
/* ============================ */
.upload-test-report {
  gap: 20px;
  /* display: flex; */
  margin-bottom: 20px;
  align-items: center;
}
.upload-test-report p {
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

/* .upload-test-report button.universal-btn {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(102deg, #5353e9 0% 100.7%);
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  width: max-content;
  padding: 20px;
  border-radius: 8px;
} */
