.navs-settings {
  height: 54px;
  position: relative;
  width: 269px;
}

.navs-settings .menu-item-4 {
  height: 54px;
  left: 14px;
  position: relative;
  width: 241px;
}

.navs-settings .frame-11 {
  border-radius: 6px;
  height: 54px;
}

.navs-settings .frame-12 {
  height: 24px;
  left: 13px;
  position: relative;
  top: 15px;
  width: 184px;
}

.navs-settings .frame-13 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.navs-settings .icon-filled-setting {
  height: 21px;
  min-width: 21px;
  position: relative;
}

.navs-settings .settings {
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.navs-settings.property-1-10-default {
  top: 20px;
}

.navs-settings.property-1-10-variant-2 {
  top: 94px;
}

.navs-settings.property-1-10-default .frame-11 {
  background-color: #ffffff1a;
}

.navs-settings.property-1-10-default .settings {
  color: #ffffff;
}

.navs-settings.property-1-10-variant-2 .settings {
  color: #888888;
}
