.allRadioBtn{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
}
.inputFelid{
    width: 200px;
}
.radioBtn{
    background-color: #cacaca;
}
.card{
    border-radius: 10px;
}
.modal-content{
    background-color: #efefef;
}
.modal-header{
 color: white;
    background-color: steelblue
}
.questionTextStyle{
    font-weight: bold;
    padding: 0px 10px 0px 10px;
}