.payment-history {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .payment-history .div-8 {
    border: 1px none;
    width: 100%;
    overflow: hidden;
  }
  
  .payment-history .span-wrapper-8 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .payment-history .text-wrapper-50 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .payment-history .overlap-17 {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000001a;
    padding: 20px;
  }
  
  .payment-history .amount-rs {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin-bottom: 10px;
  }
  
  .payment-history .text-wrapper-53 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .payment-history .paid-on {
    color: #565656;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  
  .payment-history .text-wrapper-54 {
    color: #565656;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .payment-history .span-wrapper-9 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .payment-history .calendar {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
  
  .payment-history .payment-mode {
    color: #6c6c6c;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .payment-history .text-wrapper-55 {
    color: #6c6c6c;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .payment-history .text-wrapper-56 {
    color: #000000;
  }
  
  .payment-history .ref-no {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .payment-history .text-wrapper-57 {
    color: #6c6c6c;
    font-weight: 400;
  }
  
  .payment-history .text-wrapper-58 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .payment-history .heading {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
    white-space: nowrap;
    padding: 0px 20px 0px 20px;
  }
  
  .payment-history .text-wrapper-59 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }
  
  .payment-history .heading-2 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
    white-space: nowrap;
    padding: 0px 20px 0px 20px;
  }
  