.navs-help-center {
  height: 54px;
  position: relative;
  width: 269px;
}

.navs-help-center .menu-item-2 {
  height: 54px;
  left: 14px;
  position: relative;
  width: 241px;
}

.navs-help-center .frame-5 {
  border-radius: 6px;
  height: 54px;
}

.navs-help-center .frame-6 {
  height: 24px;
  left: 13px;
  position: relative;
  top: 15px;
  width: 184px;
}

.navs-help-center .frame-7 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.navs-help-center .help-center {
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.navs-help-center.property-1-4-default {
  top: 20px;
}

.navs-help-center.property-1-4-variant-2 {
  top: 94px;
}

.navs-help-center.property-1-4-default .frame-5 {
  background-color: #ffffff1a;
}

.navs-help-center.property-1-4-default .help-center {
  color: #ffffff;
}

.navs-help-center.property-1-4-variant-2 .help-center {
  color: #888888;
}
