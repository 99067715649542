.component .frame-16 {
  align-items: center;
  display: flex;
  gap: 19px;
  width: fit-content;
  cursor: pointer;
  padding: 15px 10px;
}

.component .overview-2 {
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: fit-content;
}

.component.single-menu-selected .frame-14 {
  background-color: #e7f4db14;
  border-color: #e7f4db;
  border-right-style: solid;
  border-right-width: 3px;
}

.component.single-menu-selected .overview-2 {
  color: #e7f4db;
}

.component.single-menu-unelected .overview-2 {
  color: #75757c;
}
