.TermAndPoliciesHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 20px 0px;
}

.TermAndPoliciesHeading{
    font-family: Roboto;
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
}

.TermAndPoliciesTextWrapper{
    padding: 0px 20px 20px 20px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #00000066;
}

.BronzeHealthPlanText{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #00000066;
    margin-bottom: 20px;
}

.yearinyourpast{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: black;
}

.termandpolicytext {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    text-align: center;
  }