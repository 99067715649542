@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
body {
  font-family: "Roboto" !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


::-webkit-scrollbar {
  width: 11px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-track {
  background-color: transparentize(#bdbdbd, 0.7);
}
button:focus {
  outline: none !important;
}
a,
a:hover {
  outline: none;
  color: #fff;
  text-decoration: none !important;
}
.main-container {
  display: flex;
  background-color: #f9fbfc;
}

.main-container .sidebar {
  color: #fff;
  background-color: #111111;
  width: 310px;
  height: 100vh;
}

.main-container .content {
  flex: 1;
  overflow: hidden;
  position: relative;
  background-color: #f9fbfc;
  height: 100vh;
}

.main-container .header {
  color: #fff;
  z-index: 100;
  position: sticky;
  top: 0;
}

.main-container .scrollable-content {
  padding: 20px 35px;
  background-color: #f9fbfc;
  overflow-y: auto;
  height: calc(100vh - 55px);
  z-index: 1;
}
/* =============== Top Nav ==========  */
.main-container .content header .div-topbar {
  vertical-align: middle;
  flex: 1 1 0%;
  align-items: self-start;
  display: flex;
  border-color: rgb(226, 232, 240);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: #fff;
  justify-content: space-between;
  padding: 0 15px;
}
.main-container .content header .div-search-wrapper {
  flex: 2;
}
.main-container .content header .div-search {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: relative;
}
.main-container .content header .div-search-inner {
  align-items: center;
  gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}
.main-container .content header .icon-2 {
  min-width: 20px;
  height: 20px;
  position: relative;
}
.main-container .content header .text-wrapper-47 {
  color: #636a81;
  letter-spacing: 0;
  white-space: nowrap;
  width: 639px;
  margin-top: -1px;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.main-container .content header .div-tools {
  align-items: center;
  gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: end;
  padding: 10px;
}
.toggle {
  width: 56px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-container .content header .icon-3 {
  background-color: #e2e3f0;
  border: 0.5px solid #e2e8f0;
  border-radius: 17px;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 49px;
}
.main-container .content header .overlap-group-4 {
  width: 25px;
  height: 27px;
  position: relative;
  left: 8px;
}
.main-container .content header .chat-alt {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 0;
}
.main-container .content header .ellipse-2 {
  background-color: #dc3545;
  border: 2px solid #fff;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 15px;
}
.main-container .content header .alarm-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid #e2e8f0;
  border-radius: 17px;
  cursor: pointer;
}
.main-container .content header .alarm {
  width: 18px;
  height: 18px;
  margin: 8px;
  /* position: absolute;
  top: 8px;
  left: 8px; */
}
.main-container .content header .div-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.main-container .content header .group-7 {
  background-position: 50%;
  background-size: cover;
  width: 46px;
  height: 46px;
  margin: 0px 10px 0px 10px;
}
.main-container .content header .chevron-down {
  width: 20px;
  height: 20px;
}

.main-container .content header .text-wrapper-49 {
  color: #637381;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
.main-container .content header .top-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.top-nav .topnav-3 a.active,
.top-nav .topnav-3 a:hover {
  color: #5353e9 !important;
}
.app-header .profile {
  color: #fff;
  text-transform: uppercase;
  background: #5353e9;
  border: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-weight: 700;
}
.main-container .content header .frame-21 {
  align-items: flex-start;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  left: 11px;
}
.main-container .content header .topnav {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0;
  display: flex;
  position: relative;
}
.main-container .content header .menu-item-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}
.main-container .content header .frame-22 {
  min-width: 140px;
  height: 54px;
  position: relative;
}
.main-container .content header .frame-23 {
  align-items: center;
  gap: 19px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  top: 15px;
  left: 25px;
}
.icon-lab-test {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}
.main-container .content header .text-wrapper-6 {
  color: #888;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}
.main-container .content header .topnav-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0;
  display: flex;
  position: relative;
}
.main-container .content header .menu-item-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}
.main-container .content header .overlap-group-wrapper {
  min-width: 193px;
  height: 54px;
  position: relative;
}
.main-container .content header .overlap-group-2 {
  width: 152px;
  height: 40px;
  position: relative;
  top: 7px;
  left: 16px;
}
.main-container .content header .frame-24 {
  align-items: center;
  gap: 19px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: absolute;
  top: 8px;
  left: 9px;
}
.icon-history {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}
.main-container .content header .text-wrapper-7 {
  color: #888;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}
.main-container .content header .topnav-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.icon-medical-history {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}
.main-container .content header .text-wrapper-8 {
  color: #888;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}
.main-container .content header .topnav-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0;
  display: flex;
  position: relative;
}
.main-container .content header .menu-item-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}
.main-container .content header .frame-27 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px 25px;
  display: flex;
  position: relative;
}
.main-container .content header .frame-28 {
  align-items: center;
  gap: 19px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.main-container .content header .text-wrapper-9,
.main-container .content header .text-wrapper-dashborad {
  color: #888;
  letter-spacing: 0;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  align-items: center;
  gap: 10px;
  margin: 10px 25px;
  font-family: Satoshi-Medium, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  position: relative;
}

.content_wrapper {
  background: #f9fbfc;
  padding: 20px 35px;
}

.div-search-inner {
  display: flex;
  align-items: center;
}

.div-search-inner img {
  width: 20px;
  height: 20px;
  position: absolute;
  text-indent: 20px;
}
.InActive {
  color: #888;
  font-weight: 600;
}

/* =========================== */
.input-box {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border-color: #777;
}
/* =============================== */
.pageTitle {
  color: #000;
  padding-bottom: 15px;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.remove_button {
  background: none;
  border: none;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
}

/* Universal Button */

.lab-history-submit-btn {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(102deg, #5353e9 0% 100.7%);
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  width: max-content;
  padding: 15px 30px;
  border-radius: 8px;
  min-width: 300px;
  color: #fff;
  text-transform: capitalize;
}
.lab-history-submit-btn:focus {
  border: none;
  outline: none;
}

.lab-history-submit-btn-disabled {
  box-shadow: none;
  color: #fff;
  text-transform: capitalize;
  background: linear-gradient(102deg, #7e7ee7 0% 100.7%);
  border: none;
  border-radius: 8px;
  width: max-content;
  min-width: 300px;
  padding: 15px 30px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
}

.universal-btn {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(102deg, #5353e9 0% 100.7%);
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  width: max-content;
  padding: 15px 30px;
  border-radius: 8px;
  min-width: 200px;
  color: #fff;
  text-transform: capitalize;
}
.universal-btn:focus {
  border: none;
  outline: none;
}
.link-button-color {
  color: blue;
}
.click-mail-link {
  text-decoration-line: underline;
  color: blue;
}

/* ==============File upload============= */
.kb-file-upload {
  margin: 20px 0;
}
.file-upload-box {
  color: rgb(129, 148, 170);
  text-align: center;
  padding: 15px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 1px dashed #e1e1e8;
  background: #f9fbfc;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}
/* ======================Card Design============ */
.content-card {
  background: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 12px;
}
.add-prescription-content {
  margin-bottom: 15px;
}
.para-text-18 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.input-date-icon input[type="date"] {
  width: 100%;
  flex: 1;
}
.date-type-field-small input[type="date"] {
  width: 100%;
  flex: 1;
  max-width: 300px;
}

.input-date-icon input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(./img/calendar.png) no-repeat;
  width: 20px;
  height: 20px;
  object-fit: cover;
  flex: none;
  background-size: contain;
  padding-right: 0px;
}
.mySpinner {
  margin: 0 auto;
  width: fit-content;
}

.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  padding: 16px !important;
  font-size: 19px !important;
}

/* Remove Input type number arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* ========== */
p.error {
  color: red;
  font-size: 14px;
  margin-top: 5px !important;
  text-transform: capitalize;
}
.hospital-doctor-add-form label,
.header-title {
  text-transform: capitalize;
}
.required-border input {
  border: 2px solid red !important;
}
.visitLink {
  background: none;
  border: none;
  color: #5353e9 !important;
  text-decoration: underline;
  cursor: pointer;
}

/* common */
.ribbon {
  width: 50px;
  height: 25px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  color: #fff;
  text-shadow: 0 1px 1px #0003;
  text-transform: capitalize;
  text-align: center;
  background-color: red;
  width: 45px;
  padding: 0;
  font-size: 8px;
  display: block;
  position: absolute;
  box-shadow: 0 5px 10px #0000001a;
  letter-spacing: 1px;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: 0;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  top: 10px;
  left: 0;
  /* transform: rotate(-45deg); */
}
@keyframes borderAnimation {
  0% {
    border-width: 0;
  }
  50% {
    border-width: 5px;
  }
  100% {
    border-width: 0;
  }
}
.animated-border {
  border: 5px solid #5353e9;
  animation: borderAnimation 2s ease-in-out infinite;
}

.profileWalk {
  background-color: #fff;
  border: 1px solid #f0eeeb;
  border-radius: 10px;
  width: 300px;
  padding: 15px 20px;
  position: absolute;
  left: -50px;
  box-shadow: 0 0 24px #0000004d;
  top: 4rem;
}

.profileWalk:before {
  content: "";
  border-bottom: 15px solid #fff;
  border-left: 15px solid #0000;
  border-right: 15px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.medicalHistoryWalk {
  z-index: 999;
  background-color: #fff;
  border: 1px solid #f0eeeb;
  border-radius: 10px;
  width: 300px;
  padding: 15px 20px;
  position: absolute;
  left: 15rem;
  top: -1rem;
  box-shadow: 0px 0px 27px -6px #000000;
}
.medicalHistoryWalk:before {
  content: "";
  border: 15px solid #0000;
  border-right-color: #fff;
  position: absolute;
  top: 11%;
  left: -30px;
  transform: translateY(-50%);
}
.documentIconReport img {
  filter: invert(1);
}
/* Menu Box */
.menuPageWrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.menuPageItem {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  background-color: #5353e9;
  flex: 1;
  align-items: center;
  min-width: 100px;
  max-width: 200px;
  min-height: 150px;
  border-radius: 5px;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
.menuPageItem:hover p {
  color: #fff;
}
.menuPageImg {
  width: 62px;
  height: 62px;
  margin: 0 auto 10px;
}
.menuPageImg img {
  width: 100%;
  height: auto;
}
.menuPageItem p {
  font-size: 17px;
  font-weight: 700;
}
.menuPageItem .ellipse-21 {
  filter: blur(164px);
  background-color: #fff;
  border-radius: 65.5px / 35px;
  width: 131px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}
.text-wrapper-113 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
/* ===================Header without login======= */
.header-menu {
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
  gap: 30px;
}
.header-menu-item {
  vertical-align: middle;
  color: #888;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  display: flex;
  font-weight: 500;
}
.header-menu-item:hover,
.header-menu-item.active {
  color: #5353e9;
}
/* =============Plan screen===== */
.plan-details-section {
  object-fit: cover;
  background-image: url(../static/img/HomeBackgroundImage.jpg);
  width: 100%;
  height: calc(100vh - 87px);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  padding: 30px 0 40px 0;
}
.plan-details-wrapper {
  display: flex;
  align-items: stretch;
  gap: 20px;
}
.plan-detail-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 3px 8px #0000001a;
  flex: 1;
  text-align: center;
}
.plan-image img {
  width: 100%;
  height: 100%;
}
.plan-detail {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.plan-detail h3 {
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.plan-detail p {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.plan-button {
  min-width: 300px;
  margin: 0 auto;
  padding: 15px;
  font-size: 18px;
  text-transform: capitalize;
  font-family: Poppins, sans-serif;
}
.lear-more-watch {
  display: flex;
  justify-content: space-evenly;
}
h2.plan-pageTitle {
  text-align: center;
  font-family: Poppins, sans-serif;
  display: block;
  margin-bottom: 20px !important;
  font-weight: 600;
}
.header-menu-right {
  font-family: Poppins, sans-serif;
}
/* ==============Mobile View ================== */
.mobile-view-section {
  text-align: center;
  background: #fff;
  border: 10px solid red;
  border-color: #5353e9 #c60176 #c60176 #5353e9;
  border-radius: 10px;
  max-width: 500px;
  padding: 30px;
  font-family: Poppins, sans-serif;
}
.mobile-view-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.mobile-app-image img {
  width: 100%;
  height: auto;
}
.brand-logo {
  margin-bottom: 20px;
  background: #000;
  padding: 8px 10px;
  border-radius: 8px;
}
.app-title-subtitle h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}
.app-area-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 20px;
}
.app-title-subtitle h4 {
  text-transform: uppercase;
  font-weight: 700;
}
.download-app-title h4 {
  text-transform: uppercase;
  font-weight: 700;
}
.download-app-title h5 {
  text-transform: uppercase;
  font-weight: 600;
}
.download-app-images img {
  width: 80%;
  margin: 10px 0;
}
.mobile-view-col {
  flex: 1;
}
/* ===================Media CSS====== */
@media only screen and (max-width: 1220px) and (min-width: 1024px) {
  .main-container .content header .text-wrapper-9,
  .main-container .content header .text-wrapper-dashborad {
    gap: 5px;
    margin: 10px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .main-container .content header .text-wrapper-9,
  .main-container .content header .text-wrapper-dashborad {
    gap: 5px;
    margin: 10px;
    font-size: 14px;
  }
  .main-container .content header .topnav-3 {
    padding: 0;
  }
}
.good-luck {
  font-weight: 700;
  display: block;
  margin-top: 10px;
}
.buy-subscription-wrapper {
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.popup-img img {
  width: 100%;
}
.symtoms-wrapper {
  position: absolute;
  bottom: 15px;
}

.user-profile-image img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}
.div-profile button {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 100%;
  width: 50px;
  height: 50px;
}
.user-profile-image img {
  border-radius: 100%;
}
.profile {
  color: #fff;
  text-transform: uppercase;
  background: #5353e9;
  border: none;
  border-radius: 100%;
  width: 94px;
  height: 94px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-small {
  width: 40px;
  height: 40px;
}
.Profile-links {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.Profile-links a {
  display: block;
  width: 100%;
}
.Profile-links a:first-child {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.Profile-links button {
  padding: 0;
  align-items: baseline;
  display: contents;
  text-transform: capitalize;
}
.profile-small .profile {
  width: 40px;
  height: 40px;
}
.ProfilePopover .ProfileContainer svg {
  color: #5353e9;
}
.click-on-icon-img {
  font-size: 15px;
  font-weight: 500;
}

/* ===================== */

.unmute-tool {
  float: right;
}
.paying-user-details {
  text-align: center;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  max-width: 50%;
}
.paying-user-colum {
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 15px;
  display: flex;
  text-align: left;
}

.user-label,
.user-value {
  flex: 1;
}
.faq-inner-wrapper {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
}
.maintenance-mode {
  background-color: #fff;
  color: #333;
  text-align: center;
  background-image: url("../src/img/maintenance-bg1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}
.maintenance-mode-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.maintenance-left {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}
.uncurl-brand {
  background: #000;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin-bottom: 25px;
}

.maintenance-left,
.maintenance-right {
  flex: 1;
}
.maintenance-left img {
  width: 100%;
}
.maintenance-mode h1 {
  font-size: 3em;
  margin-bottom: 1em;
}
.maintenance-mode p {
  font-size: 1.5em;
  margin-bottom: 1em;
}
.as-of-label {
  font-size: 14px;
  font-weight: 500;
}
.graph-card-title {
  margin-bottom: 10px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
}
.card-notes-divider {
  margin: 10px 0;
}
.compare-to {
  display: block;
  font-size: 11px;
  line-height: 12px;
  text-align: left;
}
.monthly-count {
  display: block;
  font-size: 13px;
  line-height: 12px;
  text-align: left;
}
.patient-list-table {
  height: 80vh;
  overflow-x: scroll;
}
.patient-list-table table thead tr th,
.patient-list-table table tbody tr td {
  white-space: nowrap;
}

.review-form textarea {
  resize: none;
  border-radius: 8px;
  padding: 15px;
  min-width: 500px;
  height: 130px;
  display: block;
  margin-bottom: 20px;
}

.review-content-wrapper {
  gap: 20px;
  margin-bottom: 15px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
}

.my-patient-search {
  border: 1px solid #aaa;
  text-indent: 10px;
  line-height: 0;
  height: 40px;
  border-radius: 8px;
  min-width: 250px;
}

.x-axis-color-label {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.custom-tooltip {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding: 5px 10px;
  box-shadow: 2px 2px 5px #0000001a;
  width: auto !important;
  border-radius: 8px;
}

.custom-tooltip .label {
  width: auto;
  margin-bottom: 5px !important;
  line-height: 14px;
  text-transform: capitalize;
}

.custom-tooltip .desc {
  font-size: 14px;
  line-height: 14px;
}
.MUI-table-wrapper {
  margin-bottom: 30px;
}
.MUI-table .oddRow {
  background-color: #fffbf5;
}

.MUI-table tr:hover {
  /* background-color: #fffbf5; */
}
.report-icon img {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}
ul.health-trend-list li {
  font-size: 15px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
}
.history-checkbox {
  flex: 1;
  flex-basis: 30%;
}

.swal-container-above-modal {
  z-index: 1400 !important; /* Ensure Swal is above the modal (z-index 1300) */
}


@media (max-width: 600px) {
  .maintenance-mode h1 {
    font-size: 1.5em;
  }
  .maintenance-mode p {
    font-size: 0.875em;
  }
  .paying-user-details {
    max-width: none;
  }
}
.editIcon{
  font-size: 18px !important;
  margin: 3px;
  position: absolute;
}
.shopping-cart-icon{
  color: black;
  font-size: 40px;
  cursor: pointer;
}
.shopping-cart-container {
  position: relative;
  display: inline-block;
}
.shopping-cart-count {
  position: absolute;
  top: -7px; 
  right: -3px; 
  background-color: red;
  color: #fff; 
  font-size: 12px; 
  font-weight: bold; 
  padding: 1px 5px; 
  border-radius: 50%;
}
.text-wrapper-dashborad{
  color: black !important;
}

.browser-warning {
  background-color: yellow;
  padding: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .main-container .sidebar {
    display: none;
  }
  .main-container .header {
    display: none;
  }
  .main-container .scrollable-content {
    padding: 0px;
  }
}
.document-list{
  list-style-type: decimal;
  padding-left: 20px; 
}
.css-10d30g3-MuiPaper-root-MuiDialog-paper{
  max-width: 85% !important;
  width: 85% !important;
}
.p-multiselect-header {
  display: none !important; /* Hides the search field */
}
.p-multiselect-panel {
  padding: 8px 0 !important; /* Reduces padding when dropdown is open */
}