.overlapcorporate {
    background-image: url("./Images/Corporate.jpg");
    background-size: cover;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
  }

.stairs-container {
    padding: 100px 20px 20px 20px;
    display : flex
  }
  
  .step {
    border-left: 4px solid #2177a0;
    border-top: 4px solid #2177a0;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    text-align: center
  }
  
  .step-count {
    font-size: 18px;
    font-weight: bold;
    text-align: center
  }
  
  .step-label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .total {
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  
  .recharts-legend-item-text {
    display: contents;
  }

  .PurchaseOrderHeading {
    background-color: #c60176;
    padding: 15px;
    font-size: x-large;
    font-weight: 600;
    color: white;
    margin: 0px 0px 10px 0px;
}

.LabOrderDetailCard {
    background-color: transparent;
    border: 1px solid black;
    padding: 10px;
}

.purchaseOrderInformationCard {
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-bottom: 10px !important;
}

.Labnotes {
    list-style: disc;
    padding: 10px 0px 10px 20px;
}