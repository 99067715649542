.SubscriptionPageAfterLogin {
  margin-top: 20px;
}
  
  .SubscriptionPageAfterLogin .rs-per-user {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .SubscriptionPageAfterLogin .substext-wrapper {
    color: #5353e9;
    font-size: 20px;
  }
  
  .SubscriptionPageAfterLogin .span {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .SubscriptionPageAfterLogin .substext-wrapper-2 {
    font-size: 16px;
    font-weight: 400;
  }
  
  .SubscriptionPageAfterLogin .substext-wrapper-3 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
  }
  

  .SubscriptionPageAfterLogin .subsgroup-3 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  

  
  .SubscriptionPageAfterLogin .akar-icons-circle {
    height: 21px;
    width: 21px;
    margin-right: 10px;
  }
  
  .SubscriptionPageAfterLogin .subsoverlap-group-wrapper {
    border-radius: 8px;
    height: 36px;
    width: 195px;
  }
  
  .SubscriptionPageAfterLogin .overlap-group {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px #0000000d;
    border: 1px solid #5353E9;
    padding: 20px;
    margin: 0px 30px 0px 30px;
    height: 410px;
  }
  

  
  
  .SubscriptionPageAfterLogin .substext-wrapper-4 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  

  
  .SubscriptionPageAfterLogin .tier-BRONZE {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .SubscriptionPageAfterLogin .substext-wrapper-6 {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .SubscriptionPageAfterLogin .store-your-medical {
    color: #454546;
    font-family: "Roboto", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .SubscriptionPageAfterLogin .substext-wrapper-7 {
    color: #454546;
    font-family: "Roboto", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .SubscriptionPageAfterLogin .start-your-health {
    color: #454546;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
  }
  
  .SubscriptionPageAfterLogin .understand-and {
    color: #454546;
    font-family: "Roboto", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 70px;
  }
  
  .SubscriptionPageAfterLogin .text-wrapper {
    color: #5353e9;
    font-size: 20px;
  }

  .SubscriptionPageAfterLogin .text-wrapper-2 {
    font-size: 16px;
    font-weight: 400;
  }

  .SubscriptionPageAfterLogin .subsgroup-wrapper {
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-bottom: 10px;
  }

  .SubscriptionPageAfterLogin .addtocart {
    border: none;
    outline: none;
    background-color: #5353e9;
    color: #ffffff;
    padding: 15px 100px 15px 100px;
    border-radius: 8px;
    margin: 10px;
    opacity: .6;
  }

  .SubscriptionPageAfterLogin .silveraddtocart {
    border: none;
    outline: none;
    background-color: #5353e9;
    color: #ffffff;
    padding: 15px 100px 15px 100px;
    border-radius: 8px;
    margin: 10px;
  }