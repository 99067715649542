textarea:focus,
input:focus,
select:focus {
  outline: none;
}
.profile_section {
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 0 8px -5px #00000080;
}
.register_title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.register_title h2 {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
.register_title h5 {
  letter-spacing: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  background: #5353e926;
  padding: 6px 12px;
  color: #5353e9;
  border-radius: 5px;
}
.registration_tier1 .MuiGrid-item {
  margin-bottom: 5px;
}
.registration_tier1 .MuiGrid-item label {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #1c2434;
  margin-bottom: 10px;
  width: 100%;
  display: block;
}
.registration_tier1 .MuiGrid-item input,
.registration_tier1 .MuiGrid-item select {
  text-indent: 40px;
  background-color: #8888881a;
  border: none;
  border-radius: 12px;
  width: 100%;
  height: 55px;
  font-size: 18px;
  border: 1px solid #e6e6e6;
  color: #111;
}
.registration_tier1 .MuiGrid-item {
  padding-top: 0 !important;
}
.registration_tier1 .MuiGrid-item.otherLabel {
  margin-bottom: 0;
}
.registration_tier1 .MuiGrid-item.otherLabel label {
  margin-bottom: 10px !important;
}

.date-container {
  background-color: #8888881a;
  border: none;
  border-radius: 6px;
  justify-content: end;
  align-items: center;
  gap: 5px;
  padding: 10px 10px;
  display: flex;
}
.date-container .date-input {
  border: none;
  background: transparent;
  color: #1c2434;
  font-weight: 700;
}
.date-label label {
  color: #1c243466;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}
.date-input input {
  color: #1c2434;
  font-size: 16px;
  font-weight: 600;
}

.inputWithIcon {
  position: relative;
}
.inputWithIcon img {
  position: absolute;
  left: 10px;
  top: 15px;
}
.registration_tier1 .MuiGrid-item .date-field input {
  text-indent: 10px;
  padding: 0 20px;
}
.bottomMargin15 {
  margin-bottom: 15px !important;
}

.submit-button button {
  letter-spacing: 0.46px;
  background-color: #5353e9;
  min-width: 280px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  font-family: "Roboto";
  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  height: 50px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
  width: 200px;
  border-radius: 4px;
}

/* Style for the custom arrow */
.custom-select::after {
  content: "sasa";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Style for the select when focused */
.custom-select:focus {
  outline: none;
  border-color: #007bff; /* Change the border color when focused */
}
.requiredField:after {
  color: red;
  content: " *";
}
.PhoneInputCountryIconImg {
  width: 30px;
  height: 30px;
}
.register-date .date-label label {
  margin-bottom: 0;
}
.uhid-wrapper .uhid {
  color: #5353e9;
}
.uhid-wrapper h5 {
  font-size: 16px !important;
}
.phone-input-field {
  text-indent: 0 !important;
}
.react-tel-input .flag-dropdown {
  border: none !important;
  background: none !important;
}
.react-tel-input .selected-flag {
  padding-left: 15px !important;
}
.error-message {
  color: red;
  margin-top: 5px !important;
  font-size: 14px;
}
.invalidField {
  border-color: red !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}
/* Style the select element to hide the default arrow */
.custom-select-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(../../../static/img/icon-arrow-down-2.png); /* Replace with your arrow icon */
  background-repeat: no-repeat;
  background-position: right center;
  padding: 5px 25px 5px 5px; /* Adjust the padding to make room for the arrow */
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  font-size: 16px;
  cursor: pointer;
}
.phone-input-field:focus {
  box-shadow: none !important;
  outline: 0 !important;
  border-color: transparent !important;
  background-color: #8888881a !important;
  border: 1px solid #e6e6e6 !important;
}
.react-tel-input .selected-flag:before {
  content: none !important;
}

.custom-phone-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  align-items: center;
  padding: 0 5px;
  display: flex;
  background-color: #8888881a;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}
.custom-phone-input .phone-custom {
  text-indent: 0 !important;
  background: none !important;
  border: none !important;
  height: 50px !important;
}
.custom-phone-input .icon {
  width: 40px;
  padding: 5px;
  background: none;
  min-width: auto;
  height: auto;
}
.custom-phone-input .icon img {
  width: 100%;
  height: auto;
}

.custom-phone-input .country-code {
  color: #111;
  padding: 5px;
  font-size: 18px;
  line-height: 19px;
}

.custom-phone-input input {
  flex: 1;
  border: none;
  outline: none;
}
.hospital-doctor-add-form .form-group label {
  width: 100%;
}
.hospital-doctor-add-form .form-group input {
  text-indent: 10px;
  border-radius: 4px;
  width: 100%;
}

.mandatoryQues {
  color: red;
}

/* Add this CSS to your styles */
@media (max-width: 768px) {
  .desktop-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
}

@media (min-width: 769px) {
  .desktop-view {
    display: block !important;
  }
  .mobile-view {
    display: none !important;
  }
}

.card-container {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.card-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.card-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 16px;
  width: 80%;
  max-width: 500px;
}

.ModalformField {
  margin-top: 16px !important;
  width: 100%;
}

.Modalbutton {
  margin-top: 16px !important;
  width: 100%;
}

.hide-on-mobile {
  display: inline !important;
}

.capitalize-on-mobile {
  display: inline !important;
}

@media (max-width: 768px) {
  .modalBox {
    width: 90%;
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .profile_section {
    padding: 1rem !important;
  }

  .submit-button {
    width: 100% !important;
  }

  .submit-button button {
    width: 100% !important;
    min-width: 0px !important;
  }
  .date-input input {
    width: 100% !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .capitalize-on-mobile {
    text-transform: capitalize !important;
  }
}