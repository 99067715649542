.ChangePasswordModal .lock {
    width: 50px;
    height: 50px;
}

.ChangePasswordModal input[type="password"]::-ms-reveal,
.ChangePasswordModal input[type="password"]::-ms-clear {
  display: none;
}

/* For other browsers */
.ChangePasswordModal input[type="password"]::-webkit-eye,
.ChangePasswordModal input[type="password"]::-webkit-search-clear-button,
.ChangePasswordModal input[type="password"]::-webkit-search-decoration,
.ChangePasswordModal input[type="password"]::-webkit-search-results-button,
.ChangePasswordModal input[type="password"]::-webkit-search-results-decoration {
  display: none;
}