.bronzeHealthPopup .BronzeheaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 20px 0px;
}

.bronzeHealthPopup .BronzeHealthPlanHeading{
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
}

.bronzeHealthPopup .BronzeHealthPlanTextWrapper{
    padding: 0px 20px 20px 20px;
}

.bronzeHealthPopup .BronzeHealthPlanText{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #00000066;
    margin-bottom: 20px;
}

.bronzeHealthPopup .yearinyourpast{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: black;
}


.bronzeHealthPopup .question{
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
}

.bronzeHealthPopup .answer{
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
}