.medical-file-upload {
  border: 1px solid #5353e9;
  border-radius: 10px;
  margin: 20px 0 5px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #5353e9;
  align-items: center;
}
.medical-file-upload .file-upload-input {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.sample-report {
  color: #5353e9;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  text-align: right;
}
.sample-report img {
  margin-right: 5px;
  width: 22px;
  height: 22px;
}

.date-filter-box-caseHistory {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
}
.date-filter-box-caseHistory label {
  flex: none;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.date-filter-box {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;
}
.date-filter-box label {
  flex: none;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.date-filter-box input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d6d7d9;
}
.illustration-head {
  background: rgb(216, 0, 0);
  border-radius: 4px 4px 0px 0px;
  padding: 10px 15px;
  text-align: center;
  color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
}
.illustration-text {
  background: #f4f4f4;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
  box-shadow: 0 4px 4px #0000001a;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.illustration-text .text-red {
  color: #df0000;
  font-weight: 700;
}

.inline-input-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 20px;
}
.medical-input-box {
  flex: 1;
}
.input-label {
  display: block;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input-item-space {
  margin: 15px 0 5px 0;
}
.medical-input-box .input-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #d6d7d9;
  border-radius: 8px;
  background: #fff;
}
/* ==================== */
.visit-types {
  display: flex;
  justify-content: start;
  align-items: center;
  align-self: center;
}
.visit-type-wrapper p {
  margin-bottom: 15px !important;
}
.custom-radio {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
  display: flex;
  position: relative;
  gap: 20px;
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom-radio .radio-icon {
  width: 21px;
  height: 21px;
  border: 1px solid #5353e9;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-radio input:checked + .radio-icon {
  background-color: #5353e9;
}
.custom-radio .radio-icon svg {
  display: none;
}
.custom-radio input:checked + .radio-icon svg {
  display: block;
}
.input-plus-minus {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.add-medicine-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fafaff;
  color: #5353e9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.add-medicine-button:hover {
  background-color: #5353e9;
  color: #fff;
}
.add-medicine-button:hover img {
  filter: brightness(0) invert(1);
}
.medicine-list-wrapper {
  border-radius: 10px;
  border: 1px solid #d6d7d9;
  background: #fff;
  margin: 20px 0;
}
.medicine-item {
  border-bottom: 1px solid #d6d7d9;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}
.medicine-list-wrapper .medicine-item:last-child {
  border-bottom: 0;
}
.medicine-item .medicine-title {
  color: #757575;
  letter-spacing: 0.4px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.medicine-item .medicine-name {
  color: #000000de;
  letter-spacing: 0.25px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.medicine-doss .medicine-mg {
  color: #5353e9;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: right;
}
.medicine-doss .medicine-days {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.25px;
}
/* .add-medicine-table-form {
  margin-top: 20px;
} */
.add-medicine-form-head {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.add-medicine-form-head label {
  flex: 1;
  margin-bottom: 5px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.add-medicine-form-inputs {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}
.medicine-input {
  flex: 1;
}
.medicine-input input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
}
.add-medicine-btn {
  text-align: right;
  margin-bottom: 20px;
}
.disableAddBtn,
.enableAddBtn,
.addTestRemoveBtn {
  background: no-repeat;
  border: none;
}
.disableAddBtn {
  opacity: 0.5;
}
.tableWrapper .MuiTableRow-head .MuiTableCell-head {
  background-color: rgb(229, 229, 235);
  white-space: nowrap;
}
.tableWrapper .MuiTableRow-head .MuiTableSortLabel-root {
  letter-spacing: 0.4px;
  text-align: left;
  color: #757575;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
}
.tableWrapper .MuiTableBody-root tr .MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87);
  font-size: 17px !important;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
}
.tableWrapper .MuiTableBody-root tr .MuiTableCell-body img {
  width: 25px;
  height: 25px;
  /* margin-right: 8px; */
}

.medical-file-upload-blue.medical-file-upload {
  background: #5353e9;
  color: #fff;
}
.medical-file-upload-blue.medical-file-upload img {
  filter: brightness(0) invert(1);
  width: auto;
  height: fit-content;
}
.other-medical-records {
  flex: 1;
}
.documentIconReport {
  /* border: #5353e9 2px solid; */
  border-radius: 10px;
  position: relative;
}
.reportIconDelete {
  color: red;
  position: absolute;
  right: -10px;
  top: -8px;
  cursor: pointer;
}
