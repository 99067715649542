.page_404 {
  background: #fff;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.page_404 img {
  width: 100%;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  font-weight: 800;
}

.four_zero_four_bg h3 {
  font-size: 80px;
  font-weight: 800;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #5353e9;
  margin: 20px 0;
  display: inline-block;
}
