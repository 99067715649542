.diabetes-risk-wrapper {
  padding: 15px;
  margin-top: 20px;
  background-color: #eeede7 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 10px !important;
  box-shadow: 0 0 14px #e2e2e2 !important;
}
.fields-wrapper {
  /* display: flex; */
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.field-box {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 10px;
}
.field-box div p {
  text-transform: capitalize;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}
.point-input-box {
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 50px;
  text-indent: 5px;
  font-size: 12px;
  padding: 5px;
  line-height: 14px;
}
.age-year {
  font-size: 12px;
  font-weight: 600;
}
.diabetes-risk-row .field-label {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
}
.fields-wrapper small {
  color: #5353e9;
  font-weight: 600;
}
.field-box .MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: 500;
}

.total-score{
  font-size: 30px;
  margin-left: 9rem;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
}