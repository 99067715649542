.info-icon{
border-radius: 50%;
/* border: 2px solid black; */
font-size: large;
}
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.popuptextpersonal {
  border: 2px solid black;
  background-color: #dad1b9;
  color: #000;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
