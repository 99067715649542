.sign-up {
  width: 100%;
  height: 100%; 
}

.sign-up .overlap-8 {
  background-image: url(https://jdm-planet.com/wp-content/uploads/2015/01/Login-background.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.sign-up .overlap {
  border-radius: 25px 25px 0 0;
  background-color: #fdfdfe;
  border-radius: 25px;
  width: 580px;
}

.sign-up .group-18 {
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.sign-up .health-buddy {
  height: 43px;
  width: 250px;
}

.sign-up .text-wrapper-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  padding: 20px 20px 0px 20px;
}

.sign-up .text-wrapper-3 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  padding: 0px 20px 0px 20px;
}

.sign-up .group {
  padding: 0px 20px 20px 20px;
}

.sign-up .signUpButton {
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  border: none;
  outline: none;
  width: 100%;
}

.sign-up .signUpButtondisabled {
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  border: none;
  outline: none;
  width: 100%;
  opacity: 0.6;
  cursor: not-allowed;
}


.sign-up .text-wrapper-5 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}


.sign-up .overlap-group-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d6d7d9;
  border-radius: 8px;
  display: flex;
  padding: 10px;
}

.sign-up .icon-feather-phone {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.sign-up .text-wrapper-6 {
  color: #c6c6c6;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  border: none;
  outline: none;
}

.sign-up .overlap-group-3 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d6d7d9;
  border-radius: 8px;
  display: flex;
  padding: 10px;
}

.sign-up .icon-user-instance {
  left: 10px !important;
  top: 12px !important;
}

.sign-up .text-wrapper-7 {
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  border: none;
  outline: none;
  width: 100%;
}

.sign-up .text-wrapper-7::placeholder {
  color: #c6c6c6;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
}

.sign-up .text-wrapper-8 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  padding: 20px;
}

.sign-up .arrow-left {
  height: 26px;
  left: 23px;
  position: absolute;
  top: 42px;
  width: 26px;
}

.sign-up .overlap-2 {
  height: 25px;
  left: 14px;
  position: absolute;
  top: 6px;
  width: 362px;
}

.sign-up .img {
  height: 21px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 353px;
}

.sign-up .group-7 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 362px;
}

.sign-up .battery {
  height: 11px;
  left: 338px;
  position: absolute;
  top: 4px;
  width: 24px;
}

.sign-up .capacity-wrapper {
  border: 1px solid;
  border-color: #00000059;
  border-radius: 2.67px;
  height: 11px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.sign-up .capacity {
  background-color: #000000;
  border-radius: 1.33px;
  height: 7px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 18px;
}

.sign-up .cap {
  height: 4px;
  left: 23px;
  position: absolute;
  top: 4px;
  width: 1px;
}

.sign-up .wifi {
  height: 11px;
  left: 318px;
  position: absolute;
  top: 4px;
  width: 15px;
}

.sign-up .cellular-connection {
  height: 11px;
  left: 296px;
  position: absolute;
  top: 5px;
  width: 17px;
}

.sign-up .time-style {
  height: 21px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 54px !important;
}

.sign-up .iphone-x-overrides-time-black {
  font-family: "Roboto", Helvetica !important;
  font-weight: 400 !important;
  top: 3px !important;
}

.sign-up .iphone-x-overrides-instance {
  font-family: unset !important;
  font-style: unset !important;
}

.sign-up .i-agree-to-the-terms {
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.sign-up .text-wrapper-9 {
  color: #000000;
}

.sign-up .text-wrapper-10 {
  color: #5353e9;
  text-decoration: underline;
}

.sign-up .check {
  padding: 0;
  color: #5353e9 !important;
  margin-right: 10px; 
  /* margin-right: 10px; */
}

.sign-up .check .MuiSvgIcon-root {
  font-size: 28px; /* Increase font size of the checkbox icon */
}

.sign-up .group-9 {
  padding: 0px 20px 20px 20px;
}

.sign-up .p {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin-right: 10px;
}

.sign-up .group-10 {
  padding: 0px 20px 20px 20px;
  text-align: center;
}

 .sign-up .login {
  color: #5353e9;
  margin-left: 5px;
}

.sign-up .text-wrapper-13 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
}

.sign-up .line {
  height: 1px;
  left: 14px;
  object-fit: cover;
}

.sign-up .error-message {
  color: red;
  margin-top: 5px;
}