.disableBorderInCard {
  border: 0px !important;
}
.card-box-container {
  border: 2px solid black;
  border-radius: 8px;
}
.empty-card {
  display: flex;
  justify-content: right;
}
.accordion-header:before {
  background: white !important;
}
.accordion-header {
  flex-direction: column;
}
.removePadding {
  padding: 0;
}
.modal-dialog {
  max-width: 1200px;
}
.cardBodyCustome {
  height: 100px;
}
.orderIdFontSize {
  font-size: 20px;
  font-weight: bold;
}
.empty-cart-icon {
  font-size: 160px;
  margin: 14px 0px 0px 25px;
}
.for-border-radius {
  border: 2px solid black;
  border-radius: 50%;
  width: 200px;
  height: 185px;
  background-color: rgb(180, 199, 233);
}
.empty-cart-box {
  margin-top: 70px;
}
.empty-cart-text {
  font-weight: bold;
  font-size: 50px;
}
.empty-cart-text-empty {
  color: red;
}
.empty-cart-recommendations {
  padding-left: 10px;
  cursor: pointer;
}
.accordion-button {
  padding: 0.5rem 1.25rem !important;
}
.forCardItemFlex {
  display: flex;
  justify-content: start;
}
.cart-icon-btn {
  color: #007185;
  cursor: pointer;
}
.delete-btn {
  /* margin-right: 2px; */
}
.SFL-btn :hover {
  color: #045766;
}
.delete-btn :hover {
  color: #045766;
}
.for-font-cart-container {
  cursor: pointer;
  margin-bottom: 25px;
}
.hover-card {
  transition: transform 0.2s;
}
.hover-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
