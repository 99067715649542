.element-overview {
  align-items: center;
  background-color: #f9fbfc;
  border: 1px none;
  /* display: flex; */
}

.element-overview .div-welcome-heading {
  gap: 10px;
}

.element-overview .div-welcome-heading-2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.element-overview .heading {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
}

.element-overview .description {
  color: #636a81;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 20px;
}

.element-overview .div-date {
  background-color: #111111;
  border: 3px solid;
  border-color: #e2e8f0;
  border-radius: 5px;
  box-shadow: 0px 1px 2px #0000000d;
  gap: 10px;
  padding: 12px 15px;
}

.element-overview .div-date-inner {
  align-items: center;
  display: flex;
  gap: 8px;
}

.element-overview .icon-2 {
  height: 18px;
  min-width: 18px;
}

.element-overview .date {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

.element-overview .div-column-2 {
}

.element-overview .frame-25 {
  margin-bottom: 20px;
}

.element-overview .symptoms-cloud {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 16px 13px 20px;
  box-shadow: 0 7px 16px #5454e957;
  height: 100%;
}

.element-overview .symptoms-cloud-inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: -3px;
  position: relative;
}

.element-overview .word-cloud {
  height: 147px;
  min-width: 289px;
  position: relative;
}

.element-overview .overlap-group-2 {
  height: 89px;
  left: 92px;
  position: absolute;
  top: -1px;
  width: 197px;
}

.element-overview .text-wrapper-7 {
  color: #c56f4e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-overview .text-wrapper-8 {
  color: #c56f4e;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 102px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.element-overview .text-wrapper-9 {
  color: #2d7919;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 102px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.element-overview .text-wrapper-10 {
  color: #c56f4e;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 19px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 23px;
  white-space: nowrap;
}

.element-overview .text-wrapper-11 {
  color: #0000004a;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 72px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 47px;
  white-space: nowrap;
}

.element-overview .text-wrapper-12 {
  color: #5353e9;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 500;
  height: 25px;
  left: 7px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 64px;
  white-space: nowrap;
}

.element-overview .overlap {
  height: 49px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 97px;
}

.element-overview .text-wrapper-13 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 43px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-overview .text-wrapper-14 {
  color: #0000004a;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 43px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-overview .text-wrapper-15 {
  color: #0000004a;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 24px;
  white-space: nowrap;
}

.element-overview .overlap-2 {
  height: 46px;
  left: 39px;
  position: absolute;
  top: 71px;
  width: 106px;
}

.element-overview .text-wrapper-16 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  height: 25px;
  left: 2px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-overview .text-wrapper-17 {
  color: #2d7919;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.element-overview .text-wrapper-18 {
  color: #0000004a;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 84px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 121px;
  white-space: nowrap;
}

.element-overview .overlap-3 {
  height: 42px;
  left: 157px;
  position: absolute;
  top: 101px;
  width: 68px;
}

.element-overview .text-wrapper-19 {
  color: #0000004a;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-overview .text-wrapper-20 {
  color: #c56f4e;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  height: 25px;
  left: 36px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 17px;
  white-space: nowrap;
}

.element-overview .text-wrapper-21 {
  color: #c56f4e;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 25px;
  left: 198px;
  letter-spacing: 0.8px;
  line-height: 25px;
  position: absolute;
  top: 75px;
  white-space: nowrap;
}

.element-overview .medicines {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 20px 15px;
  box-shadow: 0 7px 16px #5454e957;
  height: 100%;
}

.element-overview .group-4 {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.element-overview .vector-2 {
  height: 2px;
}

.element-overview .overlap-5 {
  height: 153px;
  position: relative;
  width: 211px;
}

.element-overview .group-6 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 53px;
  width: 102px;
}

.element-overview .overlap-group-3 {
  background-color: #45c32533;
  border-radius: 50px;
  height: 100px;
  position: relative;
  width: 100px;
}

.element-overview .text-wrapper-26 {
  color: #2c7919;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  left: 33px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.element-overview .group-7 {
  height: 133px;
  left: 76px;
  position: absolute;
  top: 10px;
  width: 135px;
}

.element-overview .overlap-6 {
  background-color: #5353e9;
  border-radius: 66.5px;
  box-shadow: 0px 4px 24px #5353e961;
  height: 133px;
  position: relative;
  width: 133px;
}

.element-overview .text-wrapper-27 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  left: 48px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 54px;
  white-space: nowrap;
}

.element-overview .group-8 {
  height: 77px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 79px;
}

.element-overview .overlap-7 {
  background-color: #c56f4e2e;
  border-radius: 38.5px;
  height: 77px;
  position: relative;
  width: 77px;
}

.element-overview .text-wrapper-28 {
  color: #c56f4e;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  left: 25px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 26px;
  white-space: nowrap;
}

.element-overview .frame-29s {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.element-overview .frame-30s {
  align-items: center;
  display: flex;
  gap: 6px;
}

.element-overview .rectangle {
  background-color: #5353e9;
  border-radius: 1.5px;
  height: 3px;
  min-width: 9px;
  position: relative;
}

.element-overview .text-wrapper-29 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-overview .frame-31s {
  align-items: center;
  display: flex;
  gap: 6px;
}

.element-overview .rectangle-2 {
  background-color: #c56f4e;
  border-radius: 1.5px;
  height: 3px;
  min-width: 9px;
  position: relative;
}

.element-overview .text-wrapper-30 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-overview .frame-32s {
  align-items: center;
  display: flex;
  gap: 6px;
  position: relative;
  width: fit-content;
}

.element-overview .rectangle-3 {
  background-color: #2d7919;
  border-radius: 1.5px;
  height: 3px;
  min-width: 9px;
  position: relative;
}

.element-overview .text-wrapper-31 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-overview .div-medical-issues {
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0 7px 16px #5454e957;
  padding: 20px;
}

.element-overview .div-bubble-chart {
  height: 192px;
  width: 100%;
}

.element-overview .p {
  color: #636a81;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .div-my-health-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 20px 24px;
  box-shadow: 0 7px 16px #5454e957;
}

/* .element-overview .overlap-group-4 {
  background-color: #f4f4f4;
  border-radius: 19px !important;
  padding: 5px;
  margin-bottom: 10px;
} */

.element-overview .blue-border {
  border: 2px solid #5353e9;
}

.element-overview .ques {
  color: #000;
  letter-spacing: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
}

.element-overview .overlap-group-7 {
  background-color: #f4f4f4;
  border: 1.5px solid;
  border-color: #5353e9;
  border-radius: 19px;
  box-shadow: 0px -1px 17px -2px #5353e921;
  padding: 10px;
  margin-bottom: 10px;
}

.element-overview .text-wrapper-35 {
  color: #888888;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .div-row-2 {
  margin-bottom: 20px;
}

.element-overview .div-condition-status {
  display: flex;
  justify-content: space-between;
  background-color: #5454e9;
  border-radius: 12px;
  box-shadow: 0px 7px 16px #5454e957;
  height: 100%;
}

.element-overview .h-1 {
  color: #fff;
  letter-spacing: 0;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.element-overview .text-wrapper-38 {
  color: #ffffff;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 10px;
}

.element-overview .icon-filled-medical-wrapper {
  background-color: #ffffff33;
  border-radius: 6px;
  padding: 7px;
}

.element-overview .div-charts {
  display: flex;
}

.element-overview .div-chart-inner {
  border-radius: 12px;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 7px 16px #5454e957;
  height: 100%;
}

.element-overview .frame-33 {
  align-items: flex-end;
  border-radius: 12px 0px 0px 12px;
  display: flex;
  gap: 4px;
}

.element-overview .element-this-year {
  color: transparent;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .span {
  color: #257f4e;
  font-weight: 700;
}

.element-overview .text-wrapper-39 {
  color: #1c2434;
  font-weight: 500;
  font-size: 14px;
}

.element-overview .text-wrapper-40 {
  color: #11111140;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.element-overview .group-16 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
}

.element-overview .text-wrapper-41 {
  color: #111111;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 50px;
}

.element-overview .text-wrapper-42 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .text-wrapper-43 {
  color: #11111140;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .graph {
  align-items: center;
  /* display: flex; */
  flex: 1;
}

.element-overview .chart {
  background-image: url(../../../static/img/vector-8-2.png);
  background-size: 100% 100%;
}

.element-overview .ellipse-wrapper {
  background-image: url(../../../static/img/vector-7.png);
  background-size: 100% 100%;
}

.element-overview .div-chart-inner-2 {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 7px 16px #5454e957;
  height: 100%;
}

.element-overview .group-17 {
  display: flex;
  justify-content: space-between;
}

.element-overview .text-wrapper-47 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .text-wrapper-48 {
  color: #11111140;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .frame-40 {
  /* display: flex; */
}

.element-overview .group-18 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.element-overview .frame-41 {
  display: flex;
  align-items: center;
}

.element-overview .text-wrapper-49 {
  color: #111111;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 50px;
}

.element-overview .text-wrapper-50 {
  color: #11111140;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .frame-42 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.element-overview .group-20 {
  display: flex;
  gap: 10px;
}

.element-overview .frame-43 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.element-overview .text-wrapper-51 {
  color: #11111140;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
}

.element-overview .text-wrapper-52 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .frame-44 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.element-overview .text-wrapper-53 {
  color: #11111140;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
}

.element-overview .text-wrapper-54 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .frame-45 {
  align-items: flex-start;
  background-color: #f0eff078;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 2px 3px;
}

.element-overview .frame-46 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  height: 25px;
}

.element-overview .frame-47 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #0000000d;
  display: flex;
  gap: 5px;
  padding: 0px 10px;
}

.element-overview .text-wrapper-55 {
  color: #111111;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .frame-48 {
  align-items: flex-start;
  border-radius: 21px;
  display: flex;
  gap: 5px;
  padding: 0px 10px;
}

.element-overview .text-wrapper-56 {
  color: #5d606480;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.element-overview .frame-49 {
  align-items: flex-start;
  border-radius: 21px;
  display: flex;
  gap: 5px;
  padding: 0px 10px;
}

.element-overview .text-wrapper-57 {
  color: #5d606480;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: white !important;
}

.css-53tbrc-MuiSlider-root {
  color: #5353e9 !important;
}

.month-slider-wrapper {
  text-align: center;
  flex: 1;
}

.slider-months {
  border-top: 2px solid #5353e9;
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 5px;
}

.month-item {
  position: relative;
  margin-top: 10px;
  width: fit-content;
}

.month-item button {
  color: #000;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.month-item:first-child {
  margin-left: -20px;
}

.month-item:last-child {
  margin-right: -20px;
}

.month-item::after {
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  top: -16px;
  left: 50%;
  transform: translatex(-50%);
  border: 2px solid #5353e9;
}

.active::after,
.month-item:hover::after {
  background: #5353e9;
}

.month-year-as-of {
  color: #5454e9;
  font-size: 14px;
}

.graph-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.month-year {
  font-weight: 600;
  color: #5454e9;
}
.icon-trend-arrow-wrapper {
  justify-content: flex-start;
  gap: 10px;
  display: flex;
}
.graph-note {
  font-size: 14px;
  font-family: "Poppins";
}
.accordions-container {
  font-family: "Poppins";
}
.accordion-item {
  margin-bottom: 15px;
}
.accordion-header {
  align-items: baseline;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.accordion-header:before {
  content: "";
  background: #5353e9;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  flex-shrink: 0;
}
