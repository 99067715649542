.NotificationModel {
    padding: 0px 20px 0px 20px;
}

.name-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
}

.NotificationModel .UnreadnameContainer{
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    background-color: #d3d3d36e;
    padding: 10px;
    align-items: center;
}

.NotificationModel .nameContainer{
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    padding: 10px;
    align-items: center;
}

.NotificationModel .whatFor {
    font-family: "Roboto";
    color: black;
    font-weight: 500;
}

.NotificationModel .NotificationTitle {
    color: black;
    font-family: "Roboto";
    font-weight: 500;
    margin-bottom: 10px;
    font-size: large;
}

.NotificationModel .receivedTime {
    font-family: "Roboto";
    color: gray;
    font-size: 14px;
}