.addToCart .WhiteContainer {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px #0000000d;
    border: 1px solid #5353E9;
    padding: 20px;
    width: 580px;
}

.addToCart .overlap-4 {
    display: flex;
    justify-content: space-between;
  }

  .addToCart .text-wrapper-11 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
  }
  
  .addToCart .text-wrapper-12 {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
  }

  .addToCart .PayNowButton {
    background-color: #5353e9;
    border-radius: 8px;
    padding: 20px;
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    border: none;
    outline: none;
    width: 100%;
    margin-top: 20px;
  }