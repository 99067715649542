.dashboard .ppsheading {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}

.dashboard .div-topbar {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 1px 0px #e2e8f0;
  display: flex;
  position: relative;
  width: fit-content;
}

.dashboard .div-column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 25px;
  position: relative;
  width: fit-content;
}

.dashboard .div-search {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: fit-content;
}

.dashboard .div-search-inner {
  align-items: center;
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.dashboard .icon {
  height: 20px;
  min-width: 20px;
  position: relative;
}

.dashboard .type-to-search {
  background-color: transparent;
  border: 0;
  color: #636a81;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  min-width: 639px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.dashboard .div-tools-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 17px 36px 17px 0px;
  position: relative;
  width: fit-content;
}

.dashboard .div-tools {
  align-items: center;
  display: flex;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.dashboard .div-actions {
  height: 34px;
  min-width: 83px;
  position: relative;
}

.dashboard .overlap-group-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 34px;
}

.dashboard .overlap-group {
  height: 27px;
  left: 8px;
  position: relative;
  width: 25px;
}

.dashboard .chat-alt {
  height: 18px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 18px;
}

.dashboard .ellipse {
  background-color: #dc3545;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 10px;
}

.dashboard .alarm-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 34px;
}

.dashboard .alarm {
  height: 18px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 18px;
}

.dashboard .div-profile {
  height: 46px;
  min-width: 183px;
  position: relative;
}

.dashboard .profile {
  height: 46px;
  left: 107px;
  position: absolute;
  top: 0;
  width: 76px;
}

.dashboard .group-3 {
  background-image: url(../../../static/img/ellipse-2824.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.dashboard .chevron-down {
  height: 20px;
  left: 56px;
  position: absolute;
  top: 13px;
  width: 20px;
}

.dashboard .name {
  height: 35px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 92px;
}

.dashboard .text-wrapper {
  color: #212b36;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: -1px;
  white-space: nowrap;
}

.dashboard .text-wrapper-2 {
  color: #637381;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 26px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 20px;
  white-space: nowrap;
}

.dashboard .div-topnav {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  box-shadow: 0px 1px 0px #e2e8f0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.dashboard .div-top-navs {
  align-items: flex-start;
  display: flex;
  padding: 0px 280px 0px 15px;
  position: relative;
  width: fit-content;
}

.dashboard .topnav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.dashboard .menu-item-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-17 {
  height: 54px;
  min-width: 140px;
  position: relative;
}

.dashboard .frame-18 {
  align-items: center;
  display: flex;
  gap: 19px;
  left: 25px;
  position: relative;
  top: 15px;
  width: fit-content;
}

.dashboard .text-wrapper-3 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dashboard .menu-item-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.dashboard .menu-item-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-19 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-20 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.dashboard .text-wrapper-4 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dashboard .topnav-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.dashboard .menu-item-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-22 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.dashboard .text-wrapper-5 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dashboard .topnav-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.dashboard .menu-item-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.dashboard .frame-24 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.dashboard .text-wrapper-6 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dashboard .text-wrapper-7 {
  color: #5353e9;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dashboard .div-8 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 0;
  width: 391px;
}

.dashboard .overlap-group-26 {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  border-top-style: solid;
  border-top-width: 1px;
  height: 80px;
  width: 390px;
}

.dashboard .frame-32 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 15px;
  position: relative;
  top: 16px;
  width: 358px;
}

.dashboard .img-5 {
  height: 35px;
  position: relative;
  width: 35px;
}

.dashboard .frame-33 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 19px;
  position: relative;
}

.dashboard .frame-34 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.dashboard .group-75 {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  position: relative;
  width: 34px;
}

.dashboard .alarm-4 {
  height: 18px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 18px;
}

.dashboard .group-76 {
  background-image: url(../../../static/img/ellipse-2824.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 46px;
  position: relative;
  width: 46px;
}

.dashboard .shape-9 {
  height: 78.26%;
  left: 44.97%;
  position: absolute;
  top: 10.87%;
  width: 10.06%;
}

.dashboard .overlap-38 {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  border-top-style: solid;
  border-top-width: 1px;
  height: 80px;
  position: relative;
  width: 390px;
}

.dashboard .frame-35 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 15px;
  position: absolute;
  top: 34px;
  width: 358px;
}

.dashboard .shape-10 {
  height: 102.86%;
  left: 44.97%;
  position: absolute;
  top: 0;
  width: 10.06%;
}

.dashboard .group-78 {
  height: 21px;
  left: 13px;
  position: absolute;
  top: 5px;
  width: 362px;
}

.dashboard .battery-12 {
  height: 11px;
  left: 338px;
  position: absolute;
  top: 4px;
  width: 24px;
}

.dashboard .overlap-group-27 {
  border: 1px solid;
  border-color: #00000059;
  border-radius: 2.67px;
  height: 11px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.dashboard .capacity-12 {
  background-color: #000000;
  border-radius: 1.33px;
  height: 7px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 18px;
}

.dashboard .cap-12 {
  height: 4px;
  left: 23px;
  position: absolute;
  top: 4px;
  width: 1px;
}

.dashboard .wifi-12 {
  height: 11px;
  left: 318px;
  position: absolute;
  top: 4px;
  width: 15px;
}

.dashboard .cellular-connection-12 {
  height: 11px;
  left: 296px;
  position: absolute;
  top: 5px;
  width: 17px;
}

.dashboard .time-style-30 {
  height: 21px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 54px !important;
}

.dashboard .time-style-31 {
  font-family: "Roboto", Helvetica !important;
  font-weight: 400 !important;
  top: 3px !important;
}

.dashboard .time-style-32 {
  font-family: unset !important;
  font-style: unset !important;
}

.dashboard .this-gives-you-a {
  color: #474747;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
}

.dashboard .dashboard-div-history-status {
  background-color: #5353e9;
  border-radius: 12px;
  padding: 20px;
}

.dashboard .span-wrapper-2 {
  color: #ffffff80;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.dashboard .span-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.dashboard .overlap-group-4 {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
}

.dashboard .div-3 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 10px;
}

.dashboard .countoflabtestrecords {
  color: #5353e9;
  font-weight: 700;
}

.dashboard .span-wrapper-4 {
  color: #00000080;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.dashboard .element-2 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
}

.dashboard .no-of-times-you-have-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
}

.dashboard .your-participation-wrapper {
  background-color: #5353e9;
  border-radius: 12px;
  padding: 20px;
}

.dashboard .your-participation {
  color: #fff;
  letter-spacing: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

/* .dashboard .ppsscore {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
} */

.dashboard .overlap-6 {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
}

.dashboard .you-are-in-the-lower {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}

.dashboard .howtoCompare {
  color: #474747;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
}

/* .dashboard .bottomtext {
  color: #474747;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
} */

.dashboard .overlap-7 {
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
}

.dashboard .the-participation {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  padding: 10px;
}

.dashboard .sampleillus {
  font-weight: 700;
  color: #f20000;
}

.dashboard .profilescreen {
  color: #5353e9;
  font-weight: 700;
}

.dashboard .close-circle {
  height: 24px;
  left: 327px;
  position: absolute;
  top: 6px;
  width: 24px;
}

.dashboard .sampleillustrationheading {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #d80000;
  color: white;
  border-radius: 10px 10px 0px 0px;
  font-size: 20px;
  text-align: center;
  padding: 10px;
}
