.simple-table {
    width: 100%;
    /* border-collapse: collapse; */
    background-color: white;
    
  }
  
  .simple-table th,
  .simple-table td {
    border: 1px solid #ddd; /* Border style for cells */
    padding: 5px;
  }
  
  .simple-table th {
    background-color: #f2f2f2;
    text-align: center;
    font-weight: 600;
  }

  .simple-table tbody tr input[type=radio] {
    width: 20px;
    height: 15px;
    cursor: pointer;
  }
  
  .simple-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternate row background color */
  }

  .SubscriptionPageBeforeLogin .overlap-3 {
    padding: 20px;
    background-color: black;
  }
  
  .SubscriptionPageBeforeLogin .group-3 {
    width: 250px;
  }

  .subscriptionContainer {
    /* padding: 20px; */
    object-fit: cover;
    background-image: url(../../../static/img/HomeBackgroundImage.jpg);
    width: 100%;
    height: calc(100vh - 87px);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
    padding: 20px 50px;
  }

