.css-jwwuqf-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: white !important;
    max-width: fit-content !important;
  }
  
  .css-196zoid-MuiButtonBase-root-MuiTab-root {
    max-width: fit-content !important;
  }

  .border-r {
    border-right: 1px solid lightgray;
  }