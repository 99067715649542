.column-color {
  border: 1px solid black;
}
.row-color {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #000;
}
.body-border {
  padding: 0px 10px 0px 10px;
}
.upper-column {
  background-color: #a4c7ba;
  padding: 5px;
  border: 1px solid black;
  border-radius: 10px 10px 0px 0px;
}
.left-column {
  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: #000;
  border-radius: 0px 0px 0px 10px;
  height: 320px;
  overflow: auto;
}
.center-column {
  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: #000;
  height: 320px;
  overflow: auto;
}
.right-column {
  border-radius: 0px 0px 10px 0px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #000;
  height: 320px;
  overflow: auto;
}
.smile-icon {
  font-size: 100px;
}
.divForICon {
  text-align: center;
  padding: 10px;
}
.column-box {
  padding: 15px;
}
.left-column,
.center-column,
.right-column {
  background: #eeede7 !important;
  max-height: auto;
}
.TrafficICon {
  height: 70px;
  width: 70px;
}
.recharts-legend-item-text{
  display: none;
}
.traffic-light {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px; 
  width: 55px;
  border: 2px solid black;
  background-color: #000;
  border-radius: 8px;
  padding: 5px; 
  box-sizing: border-box; 
}
.traffic-light-red, .traffic-light-yellow, .traffic-light-green {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-bottom: 5px; 
}
.heartPulsIcon {
font-size: 100px;
}
.chart-item-health-report-card{
scale: 0.75;
border: 2px solid whitesmoke;
}

