.test{
    color: red;
}
.empty-save-icon{
    font-size: 160px;
    margin: 0px 0px 0px 73px;
}
.for-font-cart-container {
    cursor: pointer;
    margin-bottom: 25px;
  }
  .hover-card {
    transition: transform 0.2s;
  }
  .hover-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  