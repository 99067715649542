.SubscriptionPageBeforeLogin {
  background-color: #f9fbfc;
  width: 100%;
}

.SubscriptionPageBeforeLogin .div {
  background-image: url(../../../static/img/HomeBackgroundImage.jpg);
  background-size: cover;
  border: 1px;
  width: 100%;
  height: calc(100vh - 87px);
  overflow-y: scroll;
  padding: 35px 0;
  background-position: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
}

.SubscriptionPageBeforeLogin .div-topnav {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.SubscriptionPageBeforeLogin .text-wrapper-3 {
  color: #888888;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  margin-left: 10px;
}

.SubscriptionPageBeforeLogin .text-wrapper-4 {
  color: #5353e9;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  margin-left: 10px;
}

.SubscriptionPageBeforeLogin .overlap-group {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px #0000000d;
  border: 1px solid #5353e9;
  padding: 20px;
  text-align: center;
  font-family: Poppins, sans-serif;
  min-height: 450px;
  /* margin: 0px 30px 0px 30px; */
}

.SubscriptionPageBeforeLogin .rs-per-user {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 10px;
}

.SubscriptionPageBeforeLogin .text-wrapper {
  color: #5353e9;
  font-size: 20px;
}

.SubscriptionPageBeforeLogin .text-wrapper-2 {
  font-size: 16px;
  font-weight: 400;
}

.SubscriptionPageBeforeLogin .store-and-access-all {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 300;
  left: 32px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 159px;
  width: 286px;
}

.SubscriptionPageBeforeLogin .substext-wrapper-3 {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
}

.SubscriptionPageBeforeLogin .subsgroup-3 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  text-align: left;
}

.SubscriptionPageBeforeLogin .akar-icons-circle {
  height: 21px;
  width: 21px;
  margin-right: 10px;
}

.SubscriptionPageBeforeLogin .subsgroup-wrapper {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
}

.SubscriptionPageBeforeLogin .addtocart {
  color: #fff;
  background-color: #5353e9;
  border: none;
  border-radius: 8px;
  outline: none;
  margin: 10px;
  padding: 15px;
  min-width: 300px;
}

.SubscriptionPageBeforeLogin .after-days-free {
  color: #454546;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
}

.SubscriptionPageBeforeLogin .tier-BRONZE {
  color: #5353e9;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
}

.SubscriptionPageBeforeLogin .store-your-medical {
  color: #454546;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 10px;
}

.SubscriptionPageBeforeLogin .start-your-health {
  color: #454546;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.SubscriptionPageBeforeLogin .subsoverlap-3 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px #0000000d;
  border: 1px solid #5353e9;
  height: 334px;
  position: relative;
  width: 358px;
}

.SubscriptionPageBeforeLogin .tier-SILVER {
  color: #5353e9;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  left: 107px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 13px;
  width: 143px;
}

.SubscriptionPageBeforeLogin .understand-and {
  color: #454546;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 70px;
}

.SubscriptionPageBeforeLogin .overlap-3 {
  padding: 20px;
  background-color: black;
}

.SubscriptionPageBeforeLogin .group-3 {
  width: 250px;
}
