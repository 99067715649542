.tableCell{
    background-color: rgb(83, 83, 233);
    color: white !important;
    border: 1px solid lightgrey;
}
.PurchaseOrderHeading {
    background-color: #c60176;
    padding: 15px;
    font-size: x-large;
    font-weight: 600;
    color: white;
    margin: 0px 0px 10px 0px;
}

.LabOrderDetailCard {
    background-color: transparent;
    border: 1px solid black;
    padding: 10px;
}

.purchaseOrderInformationCard {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.Labnotes {
    list-style: disc;
    padding: 10px 0px 10px 20px;
}

.btn-close {
    background-color: white;
  }

.submitTestReportContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
}

@keyframes popEffect {
    0%, 100% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(255, 165, 0, 0.5); /* Light orange shadow */
    }
    50% {
      transform: scale(1.05); /* Slightly larger */
      box-shadow: 0 0 10px rgba(255, 165, 0, 0.7); /* Brighter shadow */
    }
  }
  
  /* Optional: Add a green shadow effect for 'Accepted' */
  @keyframes greenPopEffect {
    0%, 100% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(144, 238, 144, 0.5); /* Light green shadow */
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(144, 238, 144, 0.7); /* Brighter shadow */
    }
  }
  