.doctorBackground {
  background-image: url('./images/doc.jpeg');
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.DoctorChats {
    padding: 5px;
    background-color: lightgray;
    width: 50%;
    margin-bottom: 10px;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.PatientChats {
    padding: 5px;
    background-color: lightgray;
    width: 50%;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
    border-radius: 5px;
}

.PatientChatsContainer {
    display: flex;
    justify-content: end;
}

@keyframes popEffect {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  .doctor-review-button {
    color: black !important;
    font-weight: bolder !important;
    background-color: white !important;
    border: 1px solid red !important;
    animation: popEffect 0.6s infinite ease-in-out !important;
  }


.doctorPatient-modal .modal-dialog {
    max-width: 700px; /* Default max-width for larger screens */
    margin: auto;
    width: 90%; /* Ensure the modal takes up 90% of the screen width */
  }

  .profilePhotoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .SubmittedProfile  {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
  }

  .doctorContainer {
    display: flex;
    margin-left: 12%;
    padding: 30px 0px 10px 0px;
    margin-top: -20px;
  }

  
  @media (max-width: 768px) {
    .doctorPatient-modal .modal-dialog {
      max-width: 90%; /* For smaller screens, make the modal wider relative to screen size */
    }
    .doctorContainer {
      margin-left: 0px;
    }
  }