.my-subscription {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .my-subscription .div-10 {
    border: 1px none;
    overflow: hidden;
    width: 100%;
  }
  
  .my-subscription .span-wrapper-10 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .my-subscription .div-history-status-2 {
    background-color: #5353e9;
    border-radius: 12px;
    overflow: hidden;
    padding: 20px;
  }
  
  .my-subscription .span-wrapper-11 {
    color: #ffffff80;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    white-space: nowrap;
  }
  
  .my-subscription .text-wrapper-63 {
    color: #ffffff80;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
  }

  
  .my-subscription .span-wrapper-12 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
    white-space: nowrap;
  }
  
  .my-subscription .text-wrapper-65 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
  }
  
  .my-subscription .cancel-subscription {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
  }

  