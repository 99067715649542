.navs-onlineConsultation .frame {
    border-radius: 6px;
  }
  
  
  .navs-onlineConsultation .div {
    align-items: center;
    display: flex;
    gap: 19px;
    width: fit-content;
    padding: 15px 10px 0px 10px;
  }
  
  .navs-onlineConsultation .overview {
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
    width: fit-content;
  }
  
  .navs-onlineConsultation.default {
    top: 20px;
  }
  
  .navs-onlineConsultation.variant-2 {
    top: 94px;
  }
  
  .navs-onlineConsultation.default .frame {
    background-color: #ffffff1a;
  }
  
  .navs-onlineConsultation.default .overview {
    color: #ffffff;
  }
  
  .navs-onlineConsultation.variant-2 .overview {
    color: #888888;
  }
  