.patients-migration-form .field-wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.patients-migration-form .migrate-number-field .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 0;
}
.other-info {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
.patients-migration-form-wrapper {
  margin: 40px;
}
.patients-search-form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #aaa;
}

.patients-search-form form {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media (max-width: 768px) {
  .patients-search-form form {
    flex-direction: column; /* Change to column layout on smaller screens */
  }
  .patients-migration-form-wrapper {
    margin: 20px;
  }
  .patients-migration-form .field-wrapper {
    flex-direction: column;
  }
}
