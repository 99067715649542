.side-nav {
  height: 100vh;
  width: 269px;
  padding: 10px;
}

.side-nav .vector {
  height: 2px;
  width: 201px;
}

.side-nav .groupBronze {
  margin-bottom: 20px;
}


.side-nav .bronze {
  color: #cd7F32;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  font-size: 20px;
  margin-top: 10px;
}

.side-nav .silver {
  color: grey;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  font-size: 20px;
}

.side-nav .gold {
  color: gold;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  font-size: 20px;
  margin-top: 15px;
}

.side-nav .support {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 27px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 20;
  white-space: nowrap;
}

.side-nav.light {
  background-color: #ffffff;
  border-color: #e2e8f0;
  border-right-style: solid;
  border-right-width: 1px;
  left: 329px;
  top: 20px;
}

.side-nav.dark {
  background-color: #111111;
  left: 20px;
  top: 25px;
}

.side-nav.light .vector {
  left: 33px;
  top: 112px;
}

.side-nav.dark .vector {
  left: 34px;
  top: 113px;
}

.side-nav.light .img {
  left: 33px;
  top: 478px;
}

.side-nav.dark .img {
  left: 34px;
  top: 509px;
}

.side-nav.light .logo {
  left: 54px;
  top: 39px;
}

.side-nav.dark .logo {
  left: 55px;
  top: 40px;
}

.side-nav.light .medicore {
  color: #111111;
}

.side-nav.dark .medicore {
  color: #ffffff;
}

.side-nav.light .group-2 {
  position: absolute;
  bottom: 40px;
}

.side-nav.dark .group-2 {
  margin-top: 40px;
}

.side-nav .menu-item {
  text-decoration: none;
}
.side-nav .menu-item.active,
.side-nav .menu-item:hover {
  color: #fff !important;
}
.labScreen{
  font-size: 25px;
  color:#888888;
  font-family:"Roboto";
}
.active-link {
  font-size: 25px;
  color: white;
  font-family: "Roboto";
}
