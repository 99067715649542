.login-phone-screen {
  height: 100%;
  width: 100%;
}

.login-phone-screen .text-wrapper-46 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 10px;
}

/* email code */

.login-phone-screen .overlap-8 {
  background-image: url(https://jdm-planet.com/wp-content/uploads/2015/01/Login-background.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.login-phone-screen .overlap-9 {
  background-color: #fdfdfe;
  border-radius: 25px;
  padding: 40px;
  width: 580px;
}

.login-phone-screen .group-13 {
  display: flex;
  margin-bottom: 20px;
}

.login-phone-screen .overlap-group-6 {
  background-color: #f3f3f3;
  border-radius: 30px;
  padding: 5px;
  display: flex;
}

.login-phone-screen .overlap-10 {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 15px 50px 15px 50px;
}

.login-phone-screen .text-wrapper-14 {
  color: #5353e9;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.login-phone-screen .text-wrapper-15 {
  color: #858585;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  padding: 15px 50px 15px 50px;
}

.login-phone-screen .text-wrapper-16 {
  color: #5353e9;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  text-decoration: underline;
  white-space: nowrap;
  margin-bottom: 20px;
}

.login-phone-screen .group-14 {
  margin-bottom: 10px;
}

.login-phone-screen .text-wrapper-17 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}

.login-phone-screen .overlap-group-7 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d6d7d9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.login-phone-screen .phoneNumberInput {
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  border: none;
  outline: none;
  width: 100%;
}

.login-phone-screen .phoneNumberInput::placeholder {
  color: #c6c6c6;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
}


.login-phone-screen .text-wrapper-19 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 10px;
}



.login-phone-screen .by-clicking-here-i {
  color: transparent;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 20px;
}

.login-phone-screen .byclick {
  color: #000000;
}

.login-phone-screen .termsandcondition {
  color: #5353e9;
  text-decoration: underline;
  cursor: pointer;
}

.login-phone-screen .group-16 {
  margin-bottom: 20px;
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  border: none;
}

.login-phone-screen .group-16disabled {
  margin-bottom: 20px;
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  border: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.login-phone-screen .group-17 {
  margin-bottom: 20px;
}

.login-phone-screen .text-wrapper-23 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}

.login-phone-screen .don-t-have-an {
  color: transparent;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 20px;
  text-align: center;
}

.login-phone-screen .text-wrapper-24 {
  color: #000000;
  font-weight: 300;
}

.login-phone-screen .signup {
  color: #5353e9;
  font-weight: 500;
}

.login-phone-screen .group-18 {
  padding: 20px;
}

.login-phone-screen .health-buddy {
  height: 43px;
  width: 250px;
}

.login-phone-screen .icon-feather-phone-3 {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.login-phone-screen .loginContainer {
  display: flex;
  justify-content: center;
  padding: 30px 0px 0px 0px;
}

@media (max-width: 768px) {
  .login-phone-screen .overlap-9 {
    margin: 0px 10px 0px 10px;
    padding: 20px;
  }
  .login-phone-screen .group-18 {
    padding: 20px 20px 10px 20px;
  }
  .login-phone-screen .loginContainer {
    padding: 0px;
  }
}

@media (max-width: 300px) {
  .login-phone-screen .health-buddy {
    height: auto;
    width: 100%;
  }
}