.KidneyInputs {
  text-indent: -5px;
  border: 1px solid #aaa;
  border-radius: 10px;
  max-width: 60px;
  margin-left: 5px;
  padding: 5px 0;
  text-align: center;
}

.GfrResults {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.dateOfAssessment {
  font-size: 14px;
}

.kdigoHeading {
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  width: 50%;
}

.resultHeading {
  color: green;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.my-table {
  width: 50%;
  border-collapse: collapse;
}

.my-table th,
.my-table td {
  border: 1px solid black;
  padding: 3px;
  text-align: center;
  font-size: 14px;
  background-color: #eeeeee;
  vertical-align: middle;
}

.my-table th {
  background-color: white;
  font-weight: 500;
  border: 1px solid black;
}

.result-table {
  width: 50%;
  border-collapse: collapse;
}

.result-table th,
.result-table td {
  border: 1px solid #dddddd;
  padding: 3px;
  text-align: center;
  font-size: 14px;
  background-color: white;
  vertical-align: middle;
  border: 1px solid black;
}

.result-table th {
  background-color: white;
  font-weight: 500;
}

.starInformation {
  font-size: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
}

/* ================== */

.kidney-result {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.Kidney-description {
  max-width: 100%;
  width: 100%;
}
.new-table,
.new-table tr {
  border: 1px solid #aaa;
}
.new-table tr td {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #aaa;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.2;
}
.new-table {
  margin-top: 20px;
  margin-bottom: 10px;
}
td.grey-cell {
  background: #dde1e7;
}
.colorBox {
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  box-shadow: 0 0 7px 4px #e2e2e2;
  display: flex;
  flex: none;
}
.greenColor {
  background: #009484;
}
.yellowColor {
  background: #cadb2a;
}
.redColor {
  background: #f05e22;
}
.orangeColor {
  background: #faa63b;
}
.resultDescription {
  font-size: 16px;
  font-weight: 700;
  color: #c60176;
  text-transform: capitalize;
}
.gfr-rotate-text {
  writing-mode: vertical-rl;
  writing-mode: tb-rl;
  writing-mode: sideways-rl;
  transform: rotate(180deg);
  width: 50px;
}
.relative-egfr-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.labels-footer p {
  text-transform: capitalize;
}
