.menu {
}

.menu .menuheading {
  columns: black;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
.menu .menuOptionsText {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  text-align: center;
}

/* ============================ */
