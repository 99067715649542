* {
  margin: 0;
}
button {
  cursor: pointer;
}
body {
  background-color: #e5e5eb9e;
}

.health_Service_Providers_Information {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 8px -5px #00000080;
  padding: 20px;
  margin-bottom: 30px;
  width: 100%;
}
.info_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 25px;
}
.info_provider_content h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
.info_provider_content p {
  color: #929292;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
.info_provider_button button {
  padding: 9px 26px;
  border: none;
  border-radius: 4px;
  background: #5353e9;
  color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.46px;
}

.add_more_header_wrapper,
.add_more_fields_wrapper {
  flex-wrap: wrap;
  display: flex;
  /* align-items: center; */
}

/* .add_more_fields_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.add_more_header_wrapper .small-column,
.add_more_fields_wrapper .small-column {
  flex: 0 0 10%;
  padding: 10px;
}
.add_more_header_wrapper .equal-column,
.add_more_fields_wrapper .equal-column {
  flex: 1;
  box-sizing: border-box;
  padding: 0 16px;
  line-height: 48px;
  height: 48px;
  border-left: 1px solid #e2e8f0;
}

.field_header {
  background: #e5e5eb9e;
  background: #fff;
  box-shadow: 0px -1px 0px 0px #e2e8f0 inset;
  background: rgba(229, 229, 235, 0.62);
}

.add_more_fields_wrapper input {
  padding: 10px 16px;
  background: #fff;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
} */
/* ============ */
/*  */
/* ============ */

/* .add_more_fields_wrapper input,
.add_more_fields_wrapper button {
  border-left: 1px solid #e2e8f0;
}
.health_Service_Main_wrapper {
  margin-bottom: 20px;
} */
.add_more_header_wrapper {
  background: rgba(229, 229, 235, 0.62);
}
.equal-column {
  flex: 1;
}
.small-column {
  flex: 0 0 8%;
  padding: 13px 16px;
  border-bottom: 1px;
  border-left: 1px;
  border-style: solid;
  border-color: #e2e8f0;
}
.equal-column {
  flex: 1;
  padding: 10px 16px;
  border-bottom: 1px;
  border-left: 1px;
  border-style: solid;
  border-color: #e2e8f0;
  font-size: 18px;
  text-transform: capitalize;
}
.add_more_fields_wrapper .tableData:first-child {
  border-left: 0;
}
.remove_button {
  justify-content: center;
  align-self: center;
  display: flex;
  border-top: 0;
  border-right: 0;
}
.add_more_header_wrapper .small-column {
  border: none;
}
.add_more_header_wrapper .field_header {
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .health_Service_Providers_Information {
    padding: 0;
    box-shadow: none;
  }
}
