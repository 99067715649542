.questionLabel {
    font-weight: 500;
}

.inputDiet {
    /* padding: 5px; */
    /* border-radius: 5px; */
    /* border: 1px solid blue; */
    color: green;
    font-weight: 600;
    margin-left: 10px;
}

.dietHeadings {
    background-color: #ffa5006e;
    padding: 10px;
}

.dietContent {
    padding: 0px 10px 10px 10px;
}