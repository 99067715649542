/* .logout .description {
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  line-height: 22px;
} */

.logout .no {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #5353e9;
  cursor: pointer;
}

.logout .yes {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: red;
  cursor: pointer;
}
