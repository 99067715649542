.cardContainer{
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 15px #0000000d;
    border: 1px solid #5353E9;
    cursor: pointer;
}

.tier-BRONZE{
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-bottom: 10px;
}

.linksContainer{
    text-decoration: underline;
}