.formLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c2434;
  width: 100%;
  display: block;
}

.frsResults {
  color: #5353e9;
  font-size: 16px;
  font-weight: 600;
}

.heartAttackText {
  font-weight: 600;
  color: black;
  font-family: "Poppins", sans-serif !important;
}

.frsResultContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* ======================== */
.risk-table-wrapper {
  margin-top: 20px;
  background-color: #eeede7 !important;
  border-radius: 10px !important;
  box-shadow: 0 0 14px 0 #e2e2e2 !important;
  border: 1px solid #e2e2e2 !important;
}
.risk-table-wrapper td,
.risk-table-wrapper th {
  font-family: "Poppins", sans-serif !important;
  color: #000;
}
.risk-table-wrapper th {
  font-size: 15px;
  font-weight: 600;
}
.cardiac-wrapper {
  font-family: "Poppins", sans-serif;
}
.cardiac-title {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px !important;
  font-weight: 700;
}
.table-input {
  text-indent: 8px;
  border: 1px solid #aaa;
  border-radius: 4px;
  line-height: 24px;
}
.risk-date-field {
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid #aaa;
}

.resultText label {
  margin-bottom: 0;
}

.modified-frs-wrapper {
  margin-top: 20px;
  background: #eeede7;
  padding: 15px 20px;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.modified-frs-wrapper .formLabel {
  color: #000 !important;
  font-family: Poppins, sans-serif !important;
}
.result-head-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.small-note {
  padding: 5px 0 10px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  line-height: 16px;
}
.resultText {
  align-items: center;
  gap: 10px;
  font-size: 14px;
  display: flex;
  font-weight: 500;
}
.result-card {
  border: 1px solid #e2e2e2;
  padding: 20px;
  border-radius: 12px !important;
  background-color: #eeede7 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}
.highlight-value {
  color: #c60176;
  font-weight: 700;
  margin-left: 5px;
}
.treatment-field-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.treatment-field {
  display: flex;
  gap: 10px;
  align-items: center;
}
.treatment-field input {
  text-indent: 5px;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: 5px 10px;
  max-width: 180px;
}
.result-head span:last-child {
  color: #c60176;
  font-weight: 800;
  font-size: 18px;
}
