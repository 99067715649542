
.ContactUs {
    padding: 20px;
}

.ContactUs .contactusContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 20px 20px;
}

.ContactUs .ContactUsHeading{
    font-family: Roboto;
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
}

.ContactUs .cardcontainer {
    box-shadow: 0 1px 12px 0 rgba(0,0,0,.2);
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    text-align: center;
    height: 225px;
}
 
.ContactUs .officeheading {
    font-size: 20px;
    font-weight: 600;
}