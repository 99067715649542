.risk-management-table {
  margin-top: 20px;
  background-color: #eeede7 !important;
  border-radius: 10px !important;
  box-shadow: 0 0 14px 0 #e2e2e2 !important;
  border: 1px solid black !important;
}
.risk-management-table td,
.risk-management-table th {
  font-family: "Poppins", sans-serif !important;
  color: #000;
}
.risk-management-table th {
  font-size: 15px;
  font-weight: 600;
}
.data-heading td {
  font-weight: 600;
  font-size: 15px;
}
.risk-management-heading {
  font-family: Poppins, sans-serif !important;
  font-weight: 600;
}
.risk-management-footer h5 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}
.meter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.meter-content-wrapper {
  background: #eeede7;
  padding: 20px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif !important;
  margin-top: 20px;
  border: 1px solid #e2e2e2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.meter-content-inner {
  border-bottom: 1px solid #fff;
  margin-bottom: 8px;
  padding-bottom: 6px;
}
.meter-head {
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 8px;
  display: flex;
  color: #5353e9;
}
.meter-content p {
  font-size: 14px;
}
.meter-head h6 {
  font-size: 14px !important;
}
.summery .meter-content p {
  font-weight: 500;
}

.labels-footer p {
  gap: 15px;
  display: flex;
  align-items: center;
}
.labels-footer p span {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 100%;
}
.risk-level-circle {
  vertical-align: middle;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 1.5;
  color: #5353e9;
  font-weight: 700;
}
.risk-disease-map-table th,
.risk-disease-map-table td {
  font-family: "Poppins", sans-serif;
}
.innerValues {
  font-weight: 600;
}

.clickHereText {
  text-align: center;
  margin-top: 20px;
}

.clickHereButton {
  border: none;
  color: #5353e9;
  margin : 0px 5px;
}

.cvdContainer {
  background-color: orange;
  text-align: center;
  padding: 10px;
}

.cvdHeading {
  font-size: x-large;
  font-weight: 600;
}

.curolPathologyContainer {
  background-color: steelblue;
  font-size: large;
  padding: 10px;
  color: white;
  border-radius: 5px;
  margin: 20px 0px 20px 0px;
}

.liHeading {
  margin-top: 20px;
}

.customerCare {
  text-align: center;
}

.cvdDetails {
  margin-top: 15px;
  font-size: large;
}

.whatsNext {
  margin : 15px 0px 10px 0px;
}

.bookForTestButton {
  padding: 10px;
  background-color: orange;
}

.css-jwwuqf-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
  max-width: fit-content !important;
}

.css-196zoid-MuiButtonBase-root-MuiTab-root {
  max-width: fit-content !important;
}

@media (max-width: 768px) {
  .MobileViewTabs {
    display: none !important;
  }
}
