.ProfilePopover {
  padding: 20px;
}

.ProfilePopover .ProfileContainer {
  color: #1c2434;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ProfilePopover .profileDetails {
  color: #5353e9;
  font-size: 15px !important;
}
.ProfilePopover .ProfileContainer.email {
  text-transform: lowercase;
}
.profileName{
  margin-top: 4%;
  margin-left: 6%;
}
