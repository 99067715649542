.settings {
  padding: 15px;
}

.settings .div-5 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 0;
  width: 392px;
}

.settings .overlap-group-6 {
  background-color: #ffffff;
  height: 80px;
  position: relative;
  width: 390px;
}

.settings .settings-and-privacy {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.settings .text-wrapper-33 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.settings .arrow-left {
  height: 26px;
  left: 23px;
  position: absolute;
  top: 40px;
  width: 26px;
}

.settings .group-30 {
  height: 21px;
  left: 14px;
  position: absolute;
  top: 9px;
  width: 362px;
}

.settings .battery-3 {
  height: 11px;
  left: 338px;
  position: absolute;
  top: 4px;
  width: 24px;
}

.settings .overlap-group-7 {
  border-radius: 2.67px;
  height: 11px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.settings .capacity-3 {
  background-color: #000000;
  border-radius: 1.33px;
  height: 7px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 18px;
}

.settings .cap-3 {
  height: 4px;
  left: 23px;
  position: absolute;
  top: 4px;
  width: 1px;
}

.settings .wifi-3 {
  height: 11px;
  left: 318px;
  position: absolute;
  top: 4px;
  width: 15px;
}

.settings .cellular-connection-3 {
  height: 11px;
  left: 296px;
  position: absolute;
  top: 5px;
  width: 17px;
}

.settings .time-style-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 54px;
}

.settings .time-3 {
  color: #000000;
  font-family: "Arial-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.28px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 54px;
}

.settings .text-wrapper-34 {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
}

.settings .text-wrapper-35 {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.settings .overlap-10 {
  margin-bottom: 20px;
}



.settings .overlap-11 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 12px #0003
}

.settings .span-wrapper-6 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.settings .text-wrapper-36 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.settings .arrow-right {
  height: 15px;
  width: 15px;
}

.settings .line {
  height: 1px;
}

.settings .arrow-right-2 {
  height: 15px;
  width: 15px;
}

.settings .line-2 {
  height: 1px;
}

.settings .arrow-right-3 {
  height: 20px;
  left: 294px;
  position: absolute;
  top: 115px;
  width: 20px;
}

.settings .line-3 {
  height: 1px;
}

.settings .email {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.settings .text-wrapper-37 {
  color: #757575;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.settings .arrow-right-4 {
  height: 20px;
  left: 294px;
  position: absolute;
  top: 165px;
  width: 20px;
}

.settings .rectangle-wrapper {
  background-color: #5353e9;
  border-radius: 30px;
  height: 20px;
  width: 37px;
}

.settings .rectangle-5 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 16px;
  left: 19px;
  position: relative;
  top: 2px;
  width: 16px;
}

.settings .group-33 {
  background-color: #dddde5;
  border-radius: 30px;
  height: 20px;
  width: 37px;
}

.settings .rectangle-6 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 16px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 16px;
}

.settings .group-34 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.settings .overlap-12 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 12px #0003
}

.settings .cake {
  height: 21px;
  width: 21px;
  margin-right: 10px;
}

.settings .location {
  height: 21px;
  width: 21px;
  margin-right: 10px;
}

.settings .rectangle-7 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000001a;
}

.settings .line-4 {
  height: 1px;
}


.settings .arrow-right-5 {
  height: 15px;
  width: 15px;
}

.settings .delete-account {
  color: #e4400d;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.settings .text-wrapper-38 {
  color: #e4400d;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.settings .overlap-14 {
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}

.settings .group-38 {
  background-color: #ebebff;
  border-radius: 58px;
  height: 95px;
  position: relative;
  width: 95px;
}

.settings .frame-29 {
  height: 95px; /* Set the height of the frame */
  width: 95px; /* Set the width of the frame */
  object-fit: cover; /* Make the image cover the frame while maintaining aspect ratio */
  border-radius: 50%; /* To make it a circular frame, assuming square frame */
  position: absolute;
  left: 0;
  top: 0;
}

.settings .rectangle-8 {
  background-color: #5353e9;
  border-radius: 50px;
  height: 30px;
  left: 68px;
  position: absolute;
  top: 58px;
  width: 30px;
}

.settings .edit {
  height: 15px;
  left: 76px;
  position: absolute;
  top: 66px;
  width: 15px;
  cursor: pointer;
}

.settings .john-smith {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}

.settings .john-sm-gmail-com-2 {
  color: #373737;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}

.settings .element-5 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
