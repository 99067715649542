.CancelSubscription .description {
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", Helvetica;
    margin-bottom: 5px;
    line-height: 22px;
}

.CancelSubscription .no {
    font-size: 22px;
    font-weight: 600;
    font-family: "Roboto", Helvetica;
    color: #5353e9;
    cursor: pointer;
}

.CancelSubscription .yes {
    font-size: 22px;
    font-weight: 600;
    font-family: "Roboto", Helvetica;
    color: red;
    cursor: pointer;
}