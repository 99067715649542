.tableCell{
    background-color: rgb(83, 83, 233);
    color: white !important;
    border: 1px solid lightgrey;
}
.PurchaseOrderHeading {
    background-color: #c60176;
    padding: 15px;
    font-size: x-large;
    font-weight: 600;
    color: white;
    margin: 0px 0px 10px 0px;
}

.LabOrderDetailCard {
    background-color: transparent;
    border: 1px solid black;
    padding: 10px;
}

.purchaseOrderInformationCard {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.Labnotes {
    list-style: disc;
    padding: 10px 0px 10px 20px;
}

.btn-close {
    background-color: white;
  }

.submitTestReportContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
}