.navs-events .frame {
  border-radius: 6px;
}


.navs-events .div {
  align-items: center;
  display: flex;
  gap: 19px;
  width: fit-content;
  padding: 15px 10px 0px 10px;
  margin-top: -14px !important;
}

.navs-events .overview {
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: fit-content;
}

.navs-events.default {
  top: 20px;
}

.navs-events.variant-2 {
  top: 94px;
}

.navs-events.default .frame {
  background-color: #ffffff1a;
}

.navs-events.default .overview {
  color: #ffffff;
}

.navs-events.variant-2 .overview {
  color: #888888;
}
