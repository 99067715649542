/* .navs-events {
  margin-bottom: 20px;
}

.navs-events .frame-2 {
  border-radius: 6px;
}

.navs-events .frame-3 {
  margin-bottom: 10px;
}

.navs-events .frame-4 {
  align-items: center;
  display: flex;
  gap: 19px;
  width: fit-content;
  padding: 15px 10px 0px 10px;
}

.navs-events .chronology-of-events {
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: fit-content;
}

.navs-events .frame-49 {
  border-radius: 6px;
  position: relative;
}

.navs-events .frame-51 {
  align-items: center;
  display: flex;
  gap: 19px;
  padding: 0px 0px 5px 70px;
}

.navs-events .text-wrapper-106 {
  color: #888888;
  font-family: "Roboto", Helvetica;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: fit-content;
}

.navs-events.property-1-1-default .frame-2 {
  background-color: #ffffff1a;
}

.navs-events.property-1-1-default .chronology-of-events {
  color: #ffffff;
}

.navs-events.property-1-1-variant-2 .chronology-of-events {
  color: #888888;
}

.navs-events .ellipse-15 {
  background-color: #5353e9;
  border-radius: 4px;
  height: 8px;
  width: 8px;
} */


.navs-events .frame {
  border-radius: 6px;
}


.navs-events .div {
  align-items: center;
  display: flex;
  gap: 19px;
  width: fit-content;
  padding: 15px 10px 0px 10px;
}

.navs-events .overview {
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: fit-content;
}

.navs-events.default {
  top: 20px;
}

.navs-events.variant-2 {
  top: 94px;
}

.navs-events.default .frame {
  background-color: #ffffff1a;
}

.navs-events.default .overview {
  color: #ffffff;
}

.navs-events.variant-2 .overview {
  color: #888888;
}
