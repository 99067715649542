.success-component {
    /* position: fixed; */
    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    background-color: #4CAF50;
    color: white;
    /* padding: 20px; */
    border-radius: 10px;
    /* display: none; */
    margin: 125px 245px 30px 276px;
    animation: zoomInOut 1s ease-in-out;
  }
  
  @keyframes zoomInOut {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); }
  }
  
  .tick-icon {
    display: block;
    width: 325px;
    height: 50px;
    margin: 0 auto;
    fill: white;
  }
  