.forgot-password {
   height: 100%;
    width: 100%;
  }

  .forgot-password .overlap-8 {
    background-image: url(https://jdm-planet.com/wp-content/uploads/2015/01/Login-background.jpg);
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  
  .forgot-password .rectangle-6 {
    background-color: #fdfdfe;
    border-radius: 25px;
    width: 580px;
    padding: 40px;
  }

  .forgot-password .group-18 {
    padding: 20px;
  }
  
  .forgot-password .health-buddy {
    height: 43px;
    width: 250px;
  }
  
  .forgot-password .group-28 {
    height: 80px;
    left: 33px;
    position: absolute;
    top: 145px;
    width: 326px;
  }
  
  .forgot-password .phone-number {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -1px;
    white-space: nowrap;
    width: 149px;
  }
  
  .forgot-password .text-wrapper-33 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .forgot-password .group-29 {
    height: 52px;
    left: 0;
    position: absolute;
    top: 28px;
    width: 326px;
    border: 1px solid #D6D7D9;
    border-radius: 8px;
  }
  
  .forgot-password .overlap-group-6 {
    background-color: #ffffff;
    border-radius: 8px;
    height: 50px;
    position: relative;
    width: 324px;
  }
  
  .forgot-password .icon-feather-phone {
    height: 17px;
    left: 17px;
    position: absolute;
    top: 17px;
    width: 17px;
  }
  
  .forgot-password .phone-number-2 {
    color:  black;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 19px;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    width: 149px;
    outline: none;
    border: none;
  }

  .forgot-password .phone-number-2::placeholder{
    color: #c6c6c6;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
  }
  
  .forgot-password .text-wrapper-34 {
    color: #c6c6c6;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .forgot-password .span-wrapper-3 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  
  .forgot-password .text-wrapper-35 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  
  .forgot-password .kindly-provide-a {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 30px;
  }
  
  .forgot-password .text-wrapper-36 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
  }

  .forgot-password .group-17 {
    margin-bottom: 20px;
  }
  
  .forgot-password .text-wrapper-23 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .forgot-password .overlap-group-7 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #d6d7d9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .forgot-password .phoneNumberInput {
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    border: none;
    outline: none;
    width: 100%;
  }  

  .forgot-password .phoneNumberInput::placeholder {
    color: #c6c6c6;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
  }

  .forgot-password .icon-feather-phone-3 {
    width: 30px;
    height: 24px;
    margin-right: 10px;
  }
  
  .forgot-password .group-30 {
    margin-bottom: 20px;
    background-color: #5353e9;
    border-radius: 8px;
    padding: 20px;
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 100%;
    border: none;
  }

  .forgot-password .group-30disabled {
    margin-bottom: 20px;
    background-color: #5353e9;
    border-radius: 8px;
    padding: 20px;
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 100%;
    border: none;
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .forgot-password .group-31 {
    height: 21px;
    left: 14px;
    position: absolute;
    top: 9px;
    width: 362px;
  }

  
  .forgot-password .text-wrapper-38 {
    font-family: "Roboto", Helvetica;
    font-weight: 400;
  }
  
  .forgot-password .text-wrapper-39 {
    font-family: "Roboto", Helvetica;
    font-weight: 400;
    letter-spacing: 0;
  }