
.ForgotPasswordVerificationScreen .text-wrapper-55 {
  font-weight: 300;
}

.ForgotPasswordVerificationScreen .overlap-26 {
  margin-bottom: 20px;
}


.ForgotPasswordVerificationScreen .element-digit-code-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.ForgotPasswordVerificationScreen .group-43 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d6d7d9;
  border-radius: 8px;
  height: 80px;
  width: 100px;
  font-size: xx-large;
  outline: none;
  padding-left: 40px;
  margin: 0px 20px 20px 0px;
}

.ForgotPasswordVerificationScreen .group-43:focus {
  border: 2px solid #5353e9;
}


  /* .ForgotPasswordVerificationScreen .ellipse-4 {
    display: none;
  }

.ForgotPasswordVerificationScreen .ellipse-3 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 12px;
  left: 29px;
  position: relative;
  top: 21px;
  width: 12px;
} */



/* .ForgotPasswordVerificationScreen .ellipse-4 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 12px;
  left: 29px;
  position: absolute;
  top: 54px;
  width: 12px;
} */




/* email code */
.ForgotPasswordVerificationScreen {
  height: 100%;
  width: 100%;
}
/* email code */

.ForgotPasswordVerificationScreen .overlap-8 {
  background-image: url(https://jdm-planet.com/wp-content/uploads/2015/01/Login-background.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.ForgotPasswordVerificationScreen .overlap-9 {
  background-color: #fdfdfe;
  border-radius: 25px;
  padding: 40px;
  width: 580px;
}

.ForgotPasswordVerificationScreen .text-wrapper-16 {
  color: #5353e9;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  text-decoration: underline;
  white-space: nowrap;
  margin-bottom: 20px;
}

.ForgotPasswordVerificationScreen .group-14 {
  margin-bottom: 10px;
}

.ForgotPasswordVerificationScreen .text-wrapper-17 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 5px;
}


.ForgotPasswordVerificationScreen .text-wrapper-19 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 30px;
}

.ForgotPasswordVerificationScreen .group-16 {
  margin-bottom: 40px;
  margin-top: 50px;
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  border: none;
}

.ForgotPasswordVerificationScreen .group-16disabled {
  margin-bottom: 20px;
  background-color: #5353e9;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  border: none;
  opacity: 0.6;
  cursor: not-allowed;
  margin-bottom: 40px;
  margin-top: 50px;
}

.ForgotPasswordVerificationScreen .don-t-have-an {
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 20px;
  text-align: center;
}

.ForgotPasswordVerificationScreen .resend {
  color: #5353e9;
  text-decoration: underline;
  cursor: pointer;
}

.ForgotPasswordVerificationScreen .group-18 {
  padding: 20px;
}

.ForgotPasswordVerificationScreen .health-buddy {
  height: 43px;
  width: 250px;
}
