.card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

h2 {
  font-size: 2em;
  margin-top: 20px;
  color: #555;
}

h3 {
  font-size: 1.5em;
  margin-top: 10px;
}

h4 {
  font-size: 1.2em;
}

ul {
  list-style-type: disc;
}

ul li {
  margin-bottom: 5px;
}
