.element-lab-test-records {
  background-color: #f9fbfc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.element-lab-test-records .div-2 {
  background-color: #f9fbfc;
  border: 1px none;
  width: 100%;
}

.element-lab-test-records .overlap {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 269px;
}

.element-lab-test-records .text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 114px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 89px;
  white-space: nowrap;
}
.css-10dfkli-MuiTableCell-root {
  padding: 16px !important;
}
img.link-icon {
  width: 22px;
}
.link-attachment {
  letter-spacing: 0.25px;
  text-align: left;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #00f !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.disabled-link-attachmen {
  letter-spacing: 0.25px;
  text-align: left;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #b2b2ff !important;
  text-decoration: underline !important;
}

img.ellipse-2 {
  /* min-width: 29px;
  height: 29px; */
}
span.table-header {
  font-family: Satoshi-Bold, Helvetica;
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 20px;
  color: #757575;
}
.table-cell-head {
  padding: 12px 11px 11px 16px;
}

.css-1xfcrj6-MuiTableCell-root {
  padding: 5px 15px !important;
}

.css-10cbwbc-MuiTableRow-root.Mui-selected {
  background-color: #e99b5320 !important;
}
.css-10cbwbc-MuiTableRow-root {
  cursor: auto !important;
}
.main-explanation-container {
  border-radius: 17px;
  height: 350px;
  padding: 0 0 3px;
  box-shadow: 0 3px 12px #d3d3d3;
}
.anomaly-header {
  background: rgb(229, 229, 235);
  padding: 18px;
  border-radius: 15px 15px 0px 0px;
}
.anomaly-body {
  padding: 12px;
}
.show-date {
  color: #212529;
  padding: 10px 0px 3px 14px;
  font-size: 22px;
  font-weight: 700;
}
.label-text {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #757575;
}
.show-test-in-anomaly-table {
  letter-spacing: 0;
  text-align: left;
  color: #00000073;
  margin-left: 14px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}
.text-color {
  letter-spacing: 0;
  text-align: left;
  color: #1111119c;
  margin-top: 10px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
}
.header-text-style {
  letter-spacing: 0 !important;
  text-align: left;
  color: #00000073 !important;
  font-family: Roboto;
  font-size: 19px !important;
  font-weight: 700 !important;
  background: #e5e5eb !important;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.down-arrow.css-i4bv87-MuiSvgIcon-root {
  width: 30px;
  font-size: larger !important;
  padding: 2px 4px 0px 4px;
}
.input-date-field-caseHistory {
  text-align: start;
  justify-content: start;
  align-items: center;
  /* display: flex; */
  margin-bottom: 10px;
}
.input-date-field {
  text-align: end;
  justify-content: end;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.input-date {
  color: #1111119c;
  border: 1px solid #1111119c;
  border-radius: 8px;
  padding: 5px;
}

svg.refresh-list {
  cursor: pointer;
  filter: contrast(0.1);
  border: 1px solid #d6d7d9;
  border-radius: 7px;
  width: 35px;
  height: 35px;
  margin-top: -2px;
  margin-left: 10px;
  padding: 5px;
  font-size: large;
}
.css-y8ay40-MuiTableCell-root {
  padding: 10px 16px !important;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 8px 15px !important;
  font-size: 16px !important;
}

.element-lab-test-records .top-nav {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  height: 74px;
  left: 269px;
  position: absolute;
  top: 80px;
  width: 1171px;
}

.element-lab-test-records .overlap-group {
  box-shadow: 0px 1px 0px #e2e8f0;
  height: 80px;
  width: 1185px;
}

.element-lab-test-records .frame-21 {
  align-items: flex-start;
  display: flex;
  left: 11px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .topnav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .menu-item-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .overlap-group-wrapper {
  height: 54px;
  min-width: 140px;
  position: relative;
}

.element-lab-test-records .overlap-group-2 {
  height: 40px;
  left: 16px;
  position: relative;
  top: 7px;
  width: 140px;
}

.element-lab-test-records .ellipse {
  background-color: #5353e91f;
  border-radius: 19.5px/20px;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 39px;
}

.element-lab-test-records .frame-22 {
  align-items: center;
  display: flex;
  gap: 19px;
  left: 9px;
  position: absolute;
  top: 8px;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-6 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .topnav-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .menu-item-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .frame-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .frame-24 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-7 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .topnav-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .menu-item-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .frame-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .frame-26 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-8 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .topnav-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .menu-item-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .frame-28 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-9 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-dashborad {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .div-main {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 35px 157px;
  width: fit-content;
}

.element-lab-test-records .div-column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .div-labtestrecords {
  height: 394px;
  min-width: 1101px;
  position: relative;
}

.element-lab-test-records .div-table {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 59px;
  width: fit-content;
}

.element-lab-test-records .table {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .v-body {
  background-color: #ffffff;
  border-radius: 4px;
  height: 313px;
  min-width: 1101px;
  overflow: hidden;
  position: relative;
}

.element-lab-test-records .frame-29 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 173px;
}

.element-lab-test-records .th-text-start-2 {
  align-items: center;
  background-color: #e5e5eb9e;
  display: flex;
  gap: 10px;
  padding: 14px 10px 14px 14px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .span-4 {
  color: #757575;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 121px;
}

.element-lab-test-records .span-5 {
  color: #757575;
  font-family: "Material Icons", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-2 {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  padding: 14px 54px 14px 16px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-10 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 103px;
}

.element-lab-test-records .td-text-start-3 {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  padding: 14px 60px 14px 16px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-11 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-4 {
  align-items: flex-start;
  background-color: #e99b531a;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  padding: 14px 63px 14px 16px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-12 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-5 {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  padding: 14px 58px 14px 16px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-13 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-6 {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  padding: 14px 56px 14px 16px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-14 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .frame-30 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 173px;
  position: absolute;
  top: 0;
  width: 204px;
}

.element-lab-test-records .td-text-start-7 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-15 {
  color: #11111166;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-8 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-16 {
  color: #11111166;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-9 {
  align-items: center;
  align-self: stretch;
  background-color: #e99b531a;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-17 {
  color: #11111166;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-10 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-18 {
  color: #11111166;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-11 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-19 {
  color: #11111166;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .frame-31 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 377px;
  position: absolute;
  top: 0;
  width: 180px;
}

.element-lab-test-records .td-text-start-12 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .frame-32 {
  align-items: flex-start;
  background-color: #fcdbdb;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-20 {
  color: #ef4d4d;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-21 {
  color: #0000004c;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-13 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .frame-33 {
  align-items: flex-start;
  background-color: #f3eadd;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-22 {
  color: #b1751b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-14 {
  align-items: center;
  align-self: stretch;
  background-color: #e99b531a;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .frame-34 {
  align-items: flex-start;
  background-color: #1bb1b126;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-23 {
  color: #1bb1b1;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-15 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .frame-35 {
  align-items: flex-start;
  background-color: #1b2ab126;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-24 {
  color: #5353e9;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .td-text-start-16 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .frame-36 {
  align-items: flex-start;
  background-color: #39b11b26;
  border-radius: 7px;
  display: flex;
  gap: 10px;
  padding: 2px 7px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .text-wrapper-25 {
  color: #39b11b;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .frame-37 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 557px;
  position: absolute;
  top: 0;
  width: 197px;
}

.element-lab-test-records .td-text-start-17 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .ellipse-2 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-lab-test-records .text-wrapper-26 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-18 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .ellipse-3 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-lab-test-records .text-wrapper-27 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-19 {
  align-items: center;
  align-self: stretch;
  background-color: #e99b531a;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .ellipse-4 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-lab-test-records .text-wrapper-28 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-20 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .ellipse-5 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-lab-test-records .text-wrapper-29 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-21 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .ellipse-6 {
  height: 29px;
  min-width: 29px;
  object-fit: cover;
  position: relative;
}

.element-lab-test-records .text-wrapper-30 {
  color: #000000de;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.element-lab-test-records .frame-38 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 754px;
  position: absolute;
  top: 0;
  width: 187px;
}

.element-lab-test-records .td-text-start-22 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 8px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-31 {
  color: #5353e9;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-23 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-32 {
  color: #5353e9;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-24 {
  align-items: center;
  align-self: stretch;
  background-color: #e99b531a;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-33 {
  color: #5353e9;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-25 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-34 {
  color: #5353e9;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}

.element-lab-test-records .td-text-start-26 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e8f0;
  display: flex;
  gap: 10px;
  height: 53px;
  padding: 10px 16px;
  position: relative;
}

.element-lab-test-records .text-wrapper-35 {
  color: #5353e9;
  flex: 1;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}

.element-lab-test-records .frame-39 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: 941px;
  position: absolute;
  top: 0;
  width: 145px;
}

.element-lab-test-records .scroll-vertical-wrapper {
  align-items: flex-start;
  background-color: #efeff3;
  display: flex;
  gap: 10px;
  left: 1086px;
  padding: 48px 0px 0px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-lab-test-records .scroll-vertical {
  align-items: flex-start;
  background-color: #efeff3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: -125px;
  margin-right: -125px;
  padding: 4px 15px 4px 162px;
  position: relative;
  transform: rotate(-90deg);
  width: fit-content;
}

.element-lab-test-records .scroll {
  background-color: #bdbdbd;
  border-radius: 34px;
  height: 88px;
  margin-bottom: -40.5px;
  margin-top: -40.5px;
  min-width: 7px;
  position: relative;
  transform: rotate(-90deg);
}

.element-lab-test-records .div-heading {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  left: 0;
  padding: 0px 877px 0px 0px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-lab-test-records .heading {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 39px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .div-3 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .div-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 335px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .heading-wrapper {
  align-items: flex-start;
  background-color: #e5e5eb9e;
  display: flex;
  gap: 10px;
  padding: 15px 839px 15px 20px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .heading-2 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-lab-test-records .div-column-2 {
  height: 315px;
  margin-bottom: -60px;
  min-width: 1101px;
  position: relative;
}

.element-lab-test-records .overlap-group-3 {
  height: 315px;
  position: relative;
  width: 1101px;
}

.element-lab-test-records .div-body {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  left: 0;
  padding: 15px 30px 0px 20px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-lab-test-records .description {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  width: 1036px;
}

.element-lab-test-records .scroll-wrapper {
  align-items: flex-start;
  background-color: #efeff3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 966px;
  padding: 4px 15px 4px 152px;
  position: absolute;
  top: 120px;
  transform: rotate(-90deg);
  width: fit-content;
}

.element-lab-test-records .scroll-2 {
  background-color: #bdbdbd;
  border-radius: 34px;
  height: 88px;
  margin-bottom: -40.5px;
  margin-top: -40.5px;
  min-width: 7px;
  position: relative;
  transform: rotate(-90deg);
}

.element-lab-test-records .div-topbar {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 1px 0px #e2e8f0;
  display: flex;
  left: 269px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-lab-test-records .div-search-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 25px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .div-search {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .div-search-inner {
  align-items: center;
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .icon {
  height: 20px;
  min-width: 20px;
  position: relative;
}

.element-lab-test-records .text-wrapper-36 {
  color: #636a81;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 639px;
}

.element-lab-test-records .div-tools-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 17px 36px 17px 0px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .div-tools {
  align-items: center;
  display: flex;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.element-lab-test-records .div-actions {
  height: 34px;
  min-width: 83px;
  position: relative;
}

.element-lab-test-records .icon-2 {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 34px;
}

.element-lab-test-records .overlap-group-4 {
  height: 27px;
  left: 8px;
  position: relative;
  width: 25px;
}

.element-lab-test-records .chat-alt {
  height: 18px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 18px;
}

.element-lab-test-records .ellipse-7 {
  background-color: #dc3545;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 10px;
}

.element-lab-test-records .alarm-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 34px;
}

.element-lab-test-records .alarm {
  height: 18px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 18px;
}

.element-lab-test-records .div-profile {
  height: 46px;
  min-width: 183px;
  position: relative;
}

.element-lab-test-records .profile {
  height: 46px;
  left: 107px;
  position: absolute;
  top: 0;
  width: 76px;
}

.element-lab-test-records .group-3 {
  background-image: url(../../../static/img/ellipse-2824.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.element-lab-test-records .chevron-down {
  height: 20px;
  left: 56px;
  position: absolute;
  top: 13px;
  width: 20px;
}

.element-lab-test-records .name {
  height: 35px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 92px;
}

.element-lab-test-records .text-wrapper-37 {
  color: #212b36;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: -1px;
  white-space: nowrap;
}

.element-lab-test-records .text-wrapper-38 {
  color: #637381;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 26px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 20px;
  white-space: nowrap;
}
