.heading{
    font-family: "Roboto";
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: #000000;
}

.question{
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
}

.answer{
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
}

.inputcontainer{
    border:  1px solid #D6D7D9;
    margin: 0px 20px 0px 20px;
    width: 50%;
}

.searchinput{
    border: none;
    overflow: hidden;
    padding: 5px;
    width: 100%;
}