.my-usage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .my-usage .div-6 {
    border: 1px none;
    overflow: hidden;
    /* width: 390px; */
    width: 100%;
  }
  
  
  .my-usage .text-wrapper-41 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .overlap-15 {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000001a;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .my-usage .today {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    white-space: nowrap;
  }
  
  .my-usage .text-wrapper-44 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .plan-silver {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 267px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    white-space: nowrap;
  }
  
  .my-usage .text-wrapper-45 {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .sessions {
    color: #565656;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  
  .my-usage .text-wrapper-46 {
    color: #565656;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .group-41 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .my-usage .element-PM-PM {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 22px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .my-usage .clock {
    height: 18px;
    width: 18px;
  }
  
  .my-usage .element-min {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .my-usage .text-wrapper-47 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .total-day-time {
    color: #6c6c6c;
    font-family: "Roboto", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin-top: 5px;
    text-align: end;
  }
  
  .my-usage .text-wrapper-48 {
    color: #6c6c6c;
    font-family: "Roboto", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .text-wrapper-49 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .my-usage .line-5 {
    height: 1px;
    left: 13px;
    position: absolute;
    top: 91px;
    width: 323px;
  }
  
  .my-usage .overlap-16 {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000001a;
    padding: 20px;
  }
  
  
  .my-usage .plan-bronze {
    color: #5353e9;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 258px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    white-space: nowrap;
  }
  
  .my-usage .sessions-2 {
    color: #565656;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  
  .my-usage .element-PM-PM-2 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .my-usage .element-min-3 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .my-usage .clock-2 {
    height: 18px;
    width: 18px;
  }
  
  .my-usage .clock-3 {
    height: 18px;
    width: 18px;
  }
  