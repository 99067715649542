.HelpCenter {
    background-color: #f9fbfc;
    overflow: hidden;
    position: relative;
    min-height: 1080px;
  min-width: 1440px;
  }

 .HelpCenter .column-parent {
    height: 1080px;
    min-width: 1440px;
    position: relative;
  }
  
 .HelpCenter .column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: fit-content;
  }
  
 .HelpCenter .div-topbar {
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px #e2e8f0;
    display: flex;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .div-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 18px 25px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .div-search {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .div-search-inner {
    align-items: center;
    display: flex;
    gap: 20px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .icon {
    height: 20px;
    min-width: 20px;
    position: relative;
  }
  
 .HelpCenter .type-to-search {
    background-color: transparent;
    border: 0;
    color: #636a81;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    min-width: 639px;
    padding: 0;
    position: relative;
    white-space: nowrap;
  }
  
 .HelpCenter .div-tools-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 17px 36px 17px 0px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .div-tools {
    align-items: center;
    display: flex;
    gap: 32px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .div-actions {
    height: 34px;
    min-width: 83px;
    position: relative;
  }
  
 .HelpCenter .overlap-group-wrapper {
    background-color: #e2e3f0;
    border: 0.5px solid;
    border-color: #e2e8f0;
    border-radius: 17px;
    height: 34px;
    left: 49px;
    position: absolute;
    top: 0;
    width: 34px;
  }
  
 .HelpCenter .overlap-group {
    height: 27px;
    left: 8px;
    position: relative;
    width: 25px;
  }
  
 .HelpCenter .chat-alt {
    height: 18px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 18px;
  }
  
 .HelpCenter .ellipse {
    background-color: #dc3545;
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 5px;
    height: 10px;
    left: 15px;
    position: absolute;
    top: 0;
    width: 10px;
  }
  
 .HelpCenter .alarm-wrapper {
    background-color: #e2e3f0;
    border: 0.5px solid;
    border-color: #e2e8f0;
    border-radius: 17px;
    height: 34px;
    left: 0;
    position: absolute;
    top: 0;
    width: 34px;
  }
  
 .HelpCenter .alarm {
    height: 18px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 18px;
  }
  
 .HelpCenter .div-profile {
    height: 46px;
    min-width: 183px;
    position: relative;
  }
  
 .HelpCenter .profile {
    height: 46px;
    left: 107px;
    position: absolute;
    top: 0;
    width: 76px;
  }
  
 .HelpCenter .group-3 {
    background-image: url(../../../static/img/ellipse-2824.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 46px;
    left: 0;
    position: absolute;
    top: 0;
    width: 46px;
  }
  
 .HelpCenter .chevron-down {
    height: 20px;
    left: 56px;
    position: absolute;
    top: 13px;
    width: 20px;
  }
  
 .HelpCenter .name {
    height: 35px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 92px;
  }
  
 .HelpCenter .text-wrapper {
    color: #212b36;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: right;
    top: -1px;
    white-space: nowrap;
  }
  
 .HelpCenter .text-wrapper-2 {
    color: #637381;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    left: 26px;
    letter-spacing: 0;
    line-height: 14px;
    position: absolute;
    text-align: right;
    top: 20px;
    white-space: nowrap;
  }
  
 .HelpCenter .div-topnav {
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #e2e8f0;
    box-shadow: 0px 1px 0px #e2e8f0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .div-top-navs {
    align-items: flex-start;
    display: flex;
    padding: 0px 280px 0px 15px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .topnav {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .menu-item-6 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-17 {
    height: 54px;
    min-width: 140px;
    position: relative;
  }
  
 .HelpCenter .frame-18 {
    align-items: center;
    display: flex;
    gap: 19px;
    left: 25px;
    position: relative;
    top: 15px;
    width: fit-content;
  }
  
 .HelpCenter .text-wrapper-3 {
    color: #888888;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .HelpCenter .menu-item-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .menu-item-7 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-19 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 25px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-20 {
    align-items: center;
    display: flex;
    gap: 19px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .text-wrapper-4 {
    color: #888888;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .HelpCenter .topnav-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .menu-item-8 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-21 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 25px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-22 {
    align-items: center;
    display: flex;
    gap: 19px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .text-wrapper-5 {
    color: #888888;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .HelpCenter .topnav-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .menu-item-9 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-23 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 25px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .frame-24 {
    align-items: center;
    display: flex;
    gap: 19px;
    position: relative;
    width: fit-content;
  }
  
 .HelpCenter .text-wrapper-6 {
    color: #888888;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .HelpCenter .text-wrapper-7 {
    color: #888888;
    font-family: "Satoshi-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .HelpCenter .div-8 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 391px;
  }
  
  .HelpCenter .overlap-group-26 {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #e2e8f0;
    border-top-style: solid;
    border-top-width: 1px;
    height: 80px;
    width: 390px;
  }
  
  .HelpCenter .frame-32 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 15px;
    position: relative;
    top: 16px;
    width: 358px;
  }
  
  .HelpCenter .img-5 {
    height: 35px;
    position: relative;
    width: 35px;
  }
  
  .HelpCenter .frame-33 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 19px;
    position: relative;
  }
  
  .HelpCenter .frame-34 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    position: relative;
  }
  
  .HelpCenter .group-75 {
    background-color: #e2e3f0;
    border: 0.5px solid;
    border-color: #e2e8f0;
    border-radius: 17px;
    height: 34px;
    position: relative;
    width: 34px;
  }
  
  .HelpCenter .alarm-4 {
    height: 18px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 18px;
  }
  
  .HelpCenter .group-76 {
    background-image: url(../../../static/img/ellipse-2824.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 46px;
    position: relative;
    width: 46px;
  }
  
  .HelpCenter .shape-9 {
    height: 78.26%;
    left: 44.97%;
    position: absolute;
    top: 10.87%;
    width: 10.06%;
  }
  
  .HelpCenter .overlap-38 {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #e2e8f0;
    border-top-style: solid;
    border-top-width: 1px;
    height: 80px;
    position: relative;
    width: 390px;
  }
  
  .HelpCenter .frame-35 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 15px;
    position: absolute;
    top: 34px;
    width: 358px;
  }
  
  /* .HelpCenter .group-77 {
    background-image: url(../../../static/img/ellipse-2824-1.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 34px;
    position: relative;
    width: 34px;
  } */
  
  .HelpCenter .shape-10 {
    height: 102.86%;
    left: 44.97%;
    position: absolute;
    top: 0;
    width: 10.06%;
  }
  
  .HelpCenter .group-78 {
    height: 21px;
    left: 13px;
    position: absolute;
    top: 5px;
    width: 362px;
  }
  
  .HelpCenter .battery-12 {
    height: 11px;
    left: 338px;
    position: absolute;
    top: 4px;
    width: 24px;
  }
  
  .HelpCenter .overlap-group-27 {
    border: 1px solid;
    border-color: #00000059;
    border-radius: 2.67px;
    height: 11px;
    left: 0;
    position: absolute;
    top: 0;
    width: 22px;
  }
  
  .HelpCenter .capacity-12 {
    background-color: #000000;
    border-radius: 1.33px;
    height: 7px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 18px;
  }
  
  .HelpCenter .cap-12 {
    height: 4px;
    left: 23px;
    position: absolute;
    top: 4px;
    width: 1px;
  }
  
  .HelpCenter .wifi-12 {
    height: 11px;
    left: 318px;
    position: absolute;
    top: 4px;
    width: 15px;
  }
  
  .HelpCenter .cellular-connection-12 {
    height: 11px;
    left: 296px;
    position: absolute;
    top: 5px;
    width: 17px;
  }
  
  .HelpCenter .time-style-30 {
    height: 21px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 54px !important;
  }
  
  .HelpCenter .time-style-31 {
    font-family: "Roboto", Helvetica !important;
    font-weight: 400 !important;
    top: 3px !important;
  }
  
  .HelpCenter .time-style-32 {
    font-family: unset !important;
    font-style: unset !important;
  }

  .HelpCenter .text-wrapper-112 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 35px;
    font-weight: 700;
    left: 38px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    white-space: nowrap;
  }

  .HelpCenter .text-wrapper-113 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 700;
    left: 38px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 50px;
    white-space: nowrap;
  }
  
  .HelpCenter .overlap-52 {
    height: 250px;
    left: 0px;
    position: absolute;
    top: 45px;
    width: 100%;
  }
  
  .HelpCenter .rectangle-21 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--borderboloerinput);
    border-radius: 8px;
    height: 52px;
    left: 46px;
    position: absolute;
    top: 0;
    width: 360px;
  }
  
  .HelpCenter .search-normal {
    height: 22px;
    left: 71px;
    position: absolute;
    top: 15px;
    width: 22px;
  }
  
  .HelpCenter .text-wrapper-114 {
    color: #d0d0d0;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 16px;
    left: 106px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 16px;
    white-space: nowrap;
  }
  
  .HelpCenter .group-109 {
    height: 202px;
    left: 0;
    position: absolute;
    top: 34px;
    width: 217px;
    cursor: pointer;
  }
  
  .HelpCenter .overlap-53 {
    height: 202px;
    position: relative;
  }
  
  .HelpCenter .rectangle-22 {
    height: 165px;
    left: 46px;
    position: absolute;
    top: 37px;
    width: 171px;
  }
  
  .HelpCenter .img-9 {
    height: 62px;
    left: 100px;
    position: absolute;
    top: 71px;
    width: 62px;
  }
  
  .HelpCenter .ellipse-21 {
    background-color: #ffffff;
    border-radius: 65.5px/35px;
    filter: blur(164px);
    height: 70px;
    left: 0;
    position: absolute;
    top: 0;
    width: 131px;
  }
  
  .HelpCenter .group-110 {
    height: 20px;
    left: 63px;
    position: absolute;
    top: 151px;
    width: 139px;
  }
  
  .HelpCenter .text-wrapper-115 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 17px;
    font-weight: 700;
    height: 20px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .HelpCenter .group-111 {
    height: 202px;
    left: 189px;
    position: absolute;
    top: 34px;
    width: 217px;
    cursor: pointer;
  }
  
  .HelpCenter .group-112 {
    height: 19px;
    left: 95px;
    position: absolute;
    top: 152px;
    width: 125px;
  }
  
  .HelpCenter .text-wrapper-116 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .HelpCenter .group-113 {
    height: 202px;
    left: 380px;
    position: absolute;
    top: 34px;
    width: 217px;
    cursor: pointer;
  }
  
  .HelpCenter .login-and-password-wrapper {
    height: 19px;
    left: 75px;
    position: absolute;
    top: 152px;
    width: 149px;
  }
  
  .HelpCenter .text-wrapper-117 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .HelpCenter .group-114 {
    height: 202px;
    left: 760px;
    position: absolute;
    top: 34px;
    width: 217px;
    cursor: pointer;
  }
  
  .HelpCenter .group-115 {
    height: 19px;
    left: 92px;
    position: absolute;
    top: 152px;
    width: 81px;
  }
  
  .HelpCenter .group-116 {
    height: 202px;
    left: 570px;
    position: absolute;
    top: 34px;
    width: 217px;
    cursor: pointer;
  }
  
  .HelpCenter .overlap-group-35 {
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 28px;
    height: 56px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 56px;
  }
  
  .HelpCenter .vector-7 {
    height: 15px;
    left: 21px;
    position: absolute;
    top: 16px;
    width: 11px;
  }
  
  .HelpCenter .vector-8 {
    height: 2px;
    left: 25px;
    position: absolute;
    top: 34px;
    width: 2px;
  }
  
  .HelpCenter .vector-9 {
    height: 62px;
    left: 645px;
    position: absolute;
    top: -14969px;
    width: 62px;
  }
  
  .HelpCenter .group-117 {
    height: 20px;
    left: 110px;
    position: absolute;
    top: 151px;
    width: 44px;
  }
  
  .HelpCenter .text-wrapper-118 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 17px;
    font-weight: 700;
    height: 20px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }