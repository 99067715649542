/* Base Styles */
.rbc-calendar {
    font-family: 'Roboto', sans-serif;
    background: #f5f7fa;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  /* Toolbar Styling */
  .rbc-toolbar {
    background-color: #4a90e2;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .rbc-toolbar button {
    background-color: #ffffff;
    color: #4a90e2;
    border: 1px solid #4a90e2;
    border-radius: 6px;
    font-weight: bold;
    padding: 5px 12px;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .rbc-toolbar button:hover {
    background-color: #357ABD;
    color: white;
  }
  
  /* Days Header Styling */
  .rbc-header {
    background: #4a90e2;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 8px 0;
    border: 1px solid #357ABD;
    border-radius: 6px;
  }

  .rbc-today {
    background-color: #388e3c !important;
    color: black !important;
  }
  
  /* Current Day Styling */
  .rbc-day-bg.rbc-today {
    background: #e3f2fd !important;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    color: #ffffff; /* Ensure text is visible */
  }
  
  /* Event Styling */
  .rbc-event {
    background: linear-gradient(90deg, #42a5f5, #2196f3);
    color: white;
    padding: 5px 8px;
    border-radius: 6px;
    font-size: 12px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .rbc-event:hover {
    transform: scale(1.05);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Selected Slot Styling */
  .rbc-selected-cell {
    background: #dcedc8 !important;
    border: 2px dashed #8bc34a !important;
  }
  
  /* Time Column Styling */
  .rbc-time-view .rbc-time-header {
    background-color: #e3f2fd;
    border-bottom: 2px solid #4a90e2;
  }
  
  .rbc-time-content {
    background: #ffffff;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  
  /* Time Slot Styling */
  .rbc-time-slot {
    background: #f9f9f9;
    border-top: 1px solid #e0e0e0;
  }
  
  .rbc-time-slot:hover {
    background: #e3f2fd;
  }
  
  /* Customizing Today and Future Days */
  .rbc-day-slot .rbc-time-slot:nth-child(even) {
    background: #f5f5f5;
  }
  
  .rbc-day-slot .rbc-time-slot:nth-child(odd) {
    background: #ffffff;
  }
  
  /* Adding Animations */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .rbc-event {
    animation: slideIn 0.4s ease-out;
  }
  
  /* Popup Styling */
  .rbc-overlay {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    color: #333;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  