/* .home {
  background-color: #f9fbfc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

.home .div {
  border: 1px none;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  background-image: url(../../../static/img/HomeBackgroundImage.jpg);
  background-size: cover;
}

.home .div-topnav {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.home .text-wrapper-3 {
  color: #5353e9;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  margin-left: 10px;
}

.home .text-wrapper-4 {
  color: #888888;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  margin-left: 10px;
}

.home .manage-your-health {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  padding: 20px;
}

.home .overlap-group {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
}

.home .start-for-free-wrapper {
  font-family: "Roboto", Helvetica;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
}

.home .start-for-free {
  border-radius: 8px;
  background-color: #5353e9;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 120px 20px 120px;
}

.home .span-wrapper {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
  margin-bottom: 10px;
  text-align: center;
}

.home .learn-more {
  border-radius: 8px;
  color: #5353e9;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
}

.home .hometext-wrapper-3 {
  cursor: pointer;
}
.home .manage-all-your {
  color: #000;
  letter-spacing: 0;
  margin-bottom: 10px;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  text-align: -webkit-center;
}

.home .rectangle-wrapper {
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.home .img {
  height: auto;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  display: block;
}

.home .overlap-2 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000001a;
  padding: 20px;
}

.home .understand-and-start {
  color: #000;
  letter-spacing: 0;
  margin-bottom: 10px;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  text-align: -webkit-center;
}

.home .overlap-3 {
  padding: 20px;
  background-color: black;
}

.home .group-3 {
  width: 250px;
}

.home .group-4 {
  height: 21px;
  left: 14px;
  position: absolute;
  top: 9px;
  width: 362px;
}

.home .battery {
  height: 11px;
  left: 338px;
  position: absolute;
  top: 4px;
  width: 24px;
}

.home .capacity-wrapper {
  border-radius: 2.67px;
  height: 11px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.home .capacity {
  background-color: #ffffff;
  border-radius: 1.33px;
  height: 7px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 18px;
}

.home .cap {
  height: 4px;
  left: 23px;
  position: absolute;
  top: 4px;
  width: 1px;
}

.home .wifi {
  height: 11px;
  left: 318px;
  position: absolute;
  top: 4px;
  width: 15px;
}

.home .cellular-connection {
  height: 11px;
  left: 296px;
  position: absolute;
  top: 5px;
  width: 17px;
}

.home .time-style {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 54px;
}

.home .time {
  color: #ffffff;
  font-family: "Arial-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.28px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 54px;
}

.home .text-wrapper-6 {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
}

.home .text-wrapper-7 {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}
